// 售后列表
<template>
  <div class="sld_after_sales_list">
    <MemberTitle :memberTitle="curTitleId == 3 ? L['我的退货'] :curTitleId == 1 ? L['我的退款']: L['我的换货']" style="padding-left:20px"></MemberTitle>
    <div class="after_sales_list_main">
      <div class="after_sales_title flex_row_start_center">
        <router-link :to="`/member/order/aftersales?${
            curTitleId == 3 ? 'type=return' : curTitleId ==  4? 'type=returns':''
          }`" class="after_sales_title_pre cursor_pointer" :class="{ active_title: titleItem.titleId == curTitleId }"
          v-for="(titleItem, titleIndex) in afterSalesTitle.data" :key="titleIndex"
          @click="titleNav(titleItem.titleId)">
          {{ titleItem.content }}
        </router-link>
      </div>
      <div class="after_sales_list" v-if="curTitleId!=3">
        <div class="after_sales_list_title flex_row_start_center">
          <div class="title_goods_des">{{ L["商品明细"] }}</div>
          <div class="title_apply_time">{{ L["申请时间"] }}</div>
          <div class="title_apply_type">{{ L["申请类型"] }}</div>
          <div class="title_status">{{ L["状态"] }}</div>
          <div class="title_options">{{ L["操作"] }}</div>
        </div>
        <div class="after_sales_list_con">
          <div class="list_con_pre" v-for="(afterSalesItem, afterSalesIndex) in afterSalesList.data" :key="afterSalesIndex">
            <div class="list_con_pre_title flex_row_start_center">
              <p>{{ L["下单时间"] }}：{{ afterSalesItem.applyTime }}</p>
              <p>{{ "售后单号"}}：{{ afterSalesItem.afterOrderCode }}</p>
              <p class="storeName cursor_pointer" @click="toStore(afterSalesItem.goodsDetails[0].supplierUserId)">{{ afterSalesItem.seller}}</p>
            </div>
            <div class="list_con">
              <div class="list_pre flex_row_start_center">
                <div class="list_goods_des borderRight flex_row_start_center cursor_pointer"
                  @click="goGoodsDetail(afterSalesItem.goodsDetails[0].skuCode)">
                  <div class="list_goods_img" :style="{
                      backgroundImage:
                        'url(' + afterSalesItem.goodsDetails[0].goodsImage + ')',
                    }"></div>
                  <div class="goods_des">
                    <p>{{ afterSalesItem.goodsDetails.length>0?afterSalesItem.goodsDetails[0].goodsNameJoin:'--' }}</p>
                    <p>{{afterSalesItem.specValues}}</p>
                  </div>
                </div>
                <div class="list_apply_time borderRight flex_row_center_center">
                  {{ afterSalesItem.applyTime }}
                </div>
                <div class="list_apply_type borderRight flex_row_center_center">
                  {{ afterSalesItem.afterType==1?'仅退款':afterSalesItem.afterType==3?'退货':"换货" }}
                </div>
                <div class="list_status borderRight flex_row_center_center">
                  {{ afterSalesItem.afterStateName }}
                </div>
                <div class="list_options flex_column_center_center">
                  <span class="cursor_pointer" @click="goRefundDetail(afterSalesItem)">{{ L["查看详情"] }}</span>
                  <span class="cursor_pointer" v-if="
                      afterSalesItem.returnType == 2 &&
                      afterSalesItem.state == 201
                    " @click="logisticsBills(afterSalesItem)">{{ L["发货"] }}</span>
                </div>
              </div>
            </div>
          </div>
          <SldCommonEmpty v-show="!afterSalesList.data.length" totalWidth="900"></SldCommonEmpty>
          <div class="flex_row_end_center sld_pagination sld_page_bottom" v-if="
              aftersalesDetail.data &&
              aftersalesDetail.data.total
            ">
            <el-pagination @current-change="handleCurrentChange" v-model:currentPage="current" :page-size="pageSize"
              layout="prev, pager, next, jumper" :total="aftersalesDetail.data.total"
              :hide-on-single-page="true">
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="after_sales_list" v-if="curTitleId==3">
        <div class="after_sales_list_title flex_row_start_center">
          <div class="title_goods_des">{{ L["商品明细"] }}</div>
          <div class="title_apply_type">换货数量</div>
          <div class="title_apply_time">{{ L["申请时间"] }}</div>
          <div class="title_status">{{ L["状态"] }}</div>
          <div class="title_options">{{ L["操作"] }}</div>
        </div>
        <div class="after_sales_list_con">
          <div class="list_con_pre" v-for="(afterSalesItem, afterSalesIndex) in afterSalesList.data" :key="afterSalesIndex">
            <div class="list_con_pre_title flex_row_start_center">
              <p>{{ L["下单时间"] }}：{{ afterSalesItem.applyTime }}</p>
              <p>售后单号：{{ afterSalesItem.afterOrderCode }}</p>
              <p class="storeName cursor_pointer" @click="toStore(afterSalesItem.storeId)">{{ afterSalesItem.applyPerson}}</p>
            </div>
            <div class="list_con">
              <div class="list_pre flex_row_start_center">
                <div class="list_goods_des borderRight flex_row_start_center cursor_pointer"
                  @click="goGoodsDetail(afterSalesItem.afterOrderCode)">
                  <div class="list_goods_img" :style="{
                      backgroundImage:
                        'url(' + afterSalesItem.goodsDetails.length>0?afterSalesItem.goodsDetails[0].goodsImage:'--' + ')',
                    }"></div>
                  <div class="goods_des">
                    <p>{{ afterSalesItem.goodsDetails.length>0?afterSalesItem.goodsDetails[0].goodsNameJoin:'--' }}</p>
                    <p>{{afterSalesItem.specValues}}</p>
                  </div>
                </div>
                <div class="list_apply_type borderRight flex_row_center_center">
                  {{ afterSalesItem.afterNum }}
                </div>
                <div class="list_apply_time borderRight flex_row_center_center">
                  {{ afterSalesItem.applyTime }}
                </div>
                <div class="list_status borderRight flex_row_center_center">
                  {{ afterSalesItem.returnStateName }}
                </div>
                <div class="list_options flex_column_center_center">
                  <span class="cursor_pointer" @click="goRefundDetail(afterSalesItem)">{{ L["查看详情"] }}</span>
                  <span class="cursor_pointer" @click="selectBarter(afterSalesItem.orderProductId,afterSalesItem.orderState)" v-if="afterSalesItem.replacementState==200">再次申请换货</span>
                  <span class="cursor_pointer" v-if="afterSalesItem.replacementState==200" @click="selectService(afterSalesItem.orderProductId,afterSalesItem.afterOrderCode,afterSalesItem.orderState)">申请售后</span>
                  <block v-if="curTitleId!=3">
                    <span class="cursor_pointer" v-if="
                        afterSalesItem.returnType == 2 &&
                        afterSalesItem.state == 201
                      " @click="logisticsBills(afterSalesItem)">{{ L["发货"] }}</span>
                  </block>
                  <block  v-if="curTitleId==3">
                    <span class="cursor_pointer cur_sp" @click="logisticsBills(afterSalesItem)" v-if="afterSalesItem.replacementState==201">{{ L["发货"] }}</span>
                  </block>
                  <span class="cursor_pointer cur_sp" @click="takeOver(afterSalesItem)"  v-if="afterSalesItem.replacementState==204">确认收货</span>
                  <div v-if="afterSalesItem.replacementState==204" class="cursor_pointer cur_sp" @click="lookLogistics(afterSalesItem.afsSn,afterSalesItem.productLeadLittle)">{{L['查看物流']}}</div>
                </div>
              </div>
            </div>
          </div>
          <SldCommonEmpty v-show="!afterSalesList.data.length" totalWidth="900"></SldCommonEmpty>
          <div class="flex_row_end_center sld_pagination sld_page_bottom" v-if="
              aftersalesDetail.data &&
              aftersalesDetail.data.total
            ">
            <el-pagination @current-change="handleCurrentChange" v-model:currentPage="current" :page-size="pageSize"
              layout="prev, pager, next, jumper" :total="aftersalesDetail.data.total"
              :hide-on-single-page="true">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog v-model="diaflag" title="商品发货" width="560px" class="dialogs">
     <div class="aftersales_box">
        <p class="after_tips">* 请认真填写物流公司及快递单号</p>
        <div class="consignee" >
          <p class="consignee_tit">收货人姓名:</p>
          <p class="consignee_con">{{replacement.data.storeContactName}}</p>
        </div>
        <div class="consignee"  >
          <p class="consignee_tit">收货人电话:</p>
          <p class="consignee_con">{{replacement.data.storeTelphone	}}</p>
        </div>
        <div class="consignee"  >
          <p class="consignee_tit">收货人地址:</p>
          <p class="consignee_con">{{replacement.data.storeAreaInfo}}{{replacement.data.storeAddress}}</p>
        </div>
        <div class="consignee">
          <p class="consignee_tit consignee_tit_one"><span>*</span>物流公司:</p>
          <el-select-v2 v-model="curLogicsCompany" filterable :options="options" placeholder="请选择"
                    style="flex: 1;" />
        </div>
        <div class="consignee">
          <p class="consignee_tit consignee_tit_one"><span>*</span>快递单号:</p>
          <el-input placeholder="请输入快递单号" class="info_bill_input" v-model="logisticsBill" maxlength="20">
                </el-input>
        </div>
     </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="diasumber" :style="{background: $colorMain,borderColor: $colorMain}"
            >确定</el-button
          >
          <el-button @click="close" class="aft_btn">取消</el-button>
        </span>
      </template>
  </el-dialog>

  <el-dialog v-model="takeflag" title="提示" width="460px" class="dialogs">
    <div style="text-align: center;padding-bottom: 30px;">
      请确认是否收到了卖家寄出的商品?
    </div>
    <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="takesumber" style="background-color: #F30213;border-color: #F30213;"
            >确定</el-button
          >
          <el-button @click="close" class="aft_btn">取消</el-button>
        </span>
      </template>
  </el-dialog>

  <!-- 查看物流弹窗 start -->
  <el-dialog :title="L['物流信息']" v-model="show_logistics" customClass="select_reason_width"
            :before-close="handleClose" lock-sroll="false">
            <div class="logistics_info">
                <p>{{logistics_info.data.type==1?L['联系电话']:L['承运人']}}：{{logistics_info.data.expressName}}</p>
                <p>{{logistics_info.data.type==1?L['联系人']:L['运单号']}}：{{logistics_info.data.expressNumber}}</p>
            </div>
            <div class="logistics_list_con">
                <el-timeline>
                    <el-timeline-item v-for="(logistics, index) in logistics_info.data.routeList" :key="index"
                        :timestamp="logistics.acceptStation">{{logistics.acceptTime}}</el-timeline-item>
                </el-timeline>
            </div>
        </el-dialog>
        <!--  查看物流弹窗 end  -->

  </div>
</template>
<script>
  import { onMounted, reactive, ref, getCurrentInstance, watchEffect } from "vue";
  import { ElMessage, ElPagination } from "element-plus";
  import SldCommonEmpty from '../../../components/SldCommonEmpty';
  import { useRouter } from "vue-router";
  import MemberTitle from "../../../components/MemberTitle";

  export default {
    components: {
      ElPagination,
      MemberTitle,
      SldCommonEmpty
    },
    setup() {
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const router = useRouter();
      const options = ref([]);
      const LogisticsCompany = reactive({ data: [] }); //物流公司
      const curLogicsCompany = ref(''); //当前物流公司名称
      const curLogicsCompanyId = ref(''); //物流公司id
      const logisticsBill = ref(''); //物流公司id
      const show_logistics = ref(false);
      const afterSalesTitle = reactive({
        data: [
          {
            titleId: 1,
            content: L["仅退款"],
          },
          {
            titleId: 3,
            content: L["退货退款"],
          },
          {
            titleId: 4,
            content: L["换货"],
          },
        ],
      });
      const curTitleId = ref(1); //当前选中的标题id
      const afterSalesList = reactive({ data: [] }); //售后列表
      const pageSize = ref(10);
      const current = ref(1);
      const diaflag = ref(false);//显示发货框
      const takeflag = ref(false);//显示确认收货框
      const afsSn = ref('');//单号
      const aftersalesDetail = reactive({ data: {} }); //售后列表详情数据
      const replacement = reactive({data:{}});//点击换货那一行的数据


      const toStore = storeId => {
        if (storeId == 0) {
          return
        }
        proxy.$goStoreDetail(storeId);
      };



        //选择换货服务
      const selectBarter = (orderProductId, orderState) => {
        router.push({
          path: "/member/order/refund/barter",
          query: {
            orderState: orderState,
            orderProductId
          }
        });
        // proxy.$get('v3/business/front/after/sale/apply/applyInfo', {
        //   orderSn,
        //   orderProductId
        // }).then(res => {
        //   if (res.state == 200) {
        //   } else {
        //     ElMessage(res.msg)
        //   }
        // })



      }
      onMounted(() => {
        getAfterSalesDet();
        getLogisticsCompany();
      });
      router.beforeEach((to, from, next) => {
        if (to.query.type == "return") {
          curTitleId.value = 3; //退款退货
        } else if(to.query.type == "returns"){
          curTitleId.value = 4; //换货
        }else {
          curTitleId.value = 1; //仅退款
        }
        getAfterSalesDet();
        next();
      });
      watchEffect(() => {
        let tmpPath = router.currentRoute.value.fullPath;
        if (tmpPath == '/member/order/aftersales?type=return') {
          curTitleId.value = 3; //退款退货
        } else if (tmpPath == '/member/order/aftersales') {
          curTitleId.value = 1; //我的退款
        }else if(tmpPath == '/member/order/aftersales?type=returns'){
          curTitleId.value = 4; //换货
        }
        //else{
        //  return
        //}
      })
      //获取售后订单列表
      const getAfterSalesDet = () => {
        //if(curTitleId.value!=3){
          let params = {
            afterType: curTitleId.value,
            pageNum: current.value,
            pageSize: pageSize.value,
          };
          proxy.$get("api/shopp/order/after/page", params).then((res) => { //原接口：/v3/business/front/after/sale/list
            if (res.code == 200) {
              let result = res.data.records;
              aftersalesDetail.data = res.data;
              afterSalesList.data = result;
            } else {
              ElMessage.error(res.data);
            }
          });
        //}else{
        //  let params = {
        //    current: current.value,
        //    pageSize: pageSize.value,
        //  };
        //  proxy.$get("v3/business/front/after/sale/replacementList", params).then((res) => {
        //    if (res.state == 200) {
        //      let result = res.data;
        //      aftersalesDetail.data = result;
        //      afterSalesList.data = result.list;
        //    } else {
        //      ElMessage.error(res.data);
        //    }
        //  });
        //}
      };
      //获取物流公司
      const getLogisticsCompany = () => {
          proxy.$get('v3/system/front/express/list', { pageSize: 1000 }).then((res) => {
              if (res.state == 200) {
                  LogisticsCompany.data = res.data.list;
                  options.value = LogisticsCompany.data.map(item => {
                      return {
                          value: item.expressId,
                          label: item.expressName,
                      }
                  })
              } else {
                  ElMessage.error(res.msg)
              }
          })
      };
      //选择类型
      const titleNav = (titleId) => {
        curTitleId.value = titleId;
        getAfterSalesDet();
      };
      //上一页
      const handlePrevCilickChange = () => {
        current.value--;
        getAfterSalesDet();
      };
      //下一页
      const handleNextCilickChange = () => {
        current.value++;
        getAfterSalesDet();
      };
      //选择页面
      const handleCurrentChange = () => {
        getAfterSalesDet();
      };
      //查看售后详情
      const goRefundDetail = (afterSalesItem) => {
        router.push({
          path: "/member/order/refund/detail",
          query: {
            curTitleId: curTitleId.value,
            orderState: afterSalesItem.orderState,
            afsSn: afterSalesItem.afsSn,
            orderId: afterSalesItem.afterOrderCode,
          },
        });
      };
      //查看换货售后详情
      const goRefundDetails = (afterSalesItem) => {
        router.push({
          path: "/member/order/exchange/detail",
          query: {
            curTitleId: curTitleId.value,
            orderState: afterSalesItem.orderState,
            afsSn: afterSalesItem.afsSn,
            orderId: afterSalesItem.afterOrderCode,
          },
        });
      };
      //填写物流单号
      const logisticsBills = (afterSalesItem) => {
        replacement.data = afterSalesItem
        afsSn.value = afterSalesItem.afsSn;
        diaflag.value = true

        // router.push({
        //   path: "/member/order/logistics",
        //   query: {
        //     afsSn: afterSalesItem.afsSn,
        //   },
        // });
      };
      // 发货提交
      const diasumber = ()=>{
        if (curLogicsCompany.value == '') {
            ElMessage(L['请选择物流公司!']);
            return
        }
        if (logisticsBill.value == '') {
            ElMessage(L['请输入物流单号！']);
            return
        }
        let reg = /^[0-9a-zA-Z]{1,20}$/g;
        if (!reg.test(logisticsBill.value)) {
            ElMessage(L['请输入正确的物流单号！']);
            return
        }
        let params = {
            afsSn: afsSn.value,
            expressId: curLogicsCompany.value, //物流公司id
            logisticsNumber: logisticsBill.value //快递单号
        }
        proxy.$post('v3/business/front/after/sale/deliverGoods', params).then((res) => {
                    if (res.state == 200) {
                        ElMessage.success(res.msg);
                        getAfterSalesDet();
                    } else {
                        ElMessage.error(res.msg)
                    }
          })
        afsSn.value = ''
        diaflag.value = false
      };
      //唤起换货确认收货框
      const takeOver = (afterSalesItem)=>{
        afsSn.value = afterSalesItem.afsSn;
        takeflag.value = true;
      }
      //关闭模态框
      const close = ()=>{
        afsSn.value = '';
        takeflag.value = false;
        diaflag.value = false;
      };
      // 换货确认收货事件
      const takesumber = ()=>{
        let params = {};
        params.afsSn = afsSn.value
        proxy.$post('v3/business/front/after/sale/receive', params).then((res) => {
                    if (res.state == 200) {
                        ElMessage.success(res.msg);
                        takeflag.value = false;
                        getAfterSalesDet();
                    } else {
                        ElMessage.error(res.msg)
                    }
          })
      }
      //去商品详情页
      const goGoodsDetail = (productId) => {
        proxy.$goGoodsDetail(productId);
      };
      //选择售后服务
      const selectService = (orderProductId, afterOrderCode,orderState) => {
        proxy.$get('v3/business/front/after/sale/apply/applyInfo', {
          afterOrderCode,
          orderProductId
        }).then(res => {
          if (res.state == 200) {
            router.push({
              path: "/member/order/refund/apply",
              query: {
                orderState: orderState,
                orderProductId
              }
            });
          } else {
            ElMessage(res.msg)
          }
        })



      }
      //显示查看物流信息
      const logistics_info = reactive({ data: {} });
      const lookLogistics = afsSn => {
                proxy.$get("/v3/business/front/logistics/afs/getTrace", { afsSn }).then(res => {
                    if (res.state == 200) {
                        show_logistics.value = true;
                        logistics_info.data = res.data;
                    } else {
                        ElMessage(res.msg);
                    }
                })
            };

      return {
        L,
        afterSalesTitle,
        curTitleId,
        afterSalesList,
        titleNav,
        lookLogistics,
        pageSize,
        current,
        logistics_info,
        aftersalesDetail,
        handlePrevCilickChange,
        handleNextCilickChange,
        handleCurrentChange,
        goRefundDetail,
        logisticsBills,
        logisticsBill,
        goGoodsDetail,
        toStore,
        afsSn,
        diaflag,
        options,
        LogisticsCompany,
        curLogicsCompany,
        curLogicsCompanyId,
        diasumber,
        takeOver,
        takeflag,
        close,
        goRefundDetails,
        replacement,
        takesumber,
        show_logistics,
        selectBarter,
        selectService
      };
    },
  };
</script>
<style lang="scss" scoped>
  @import "../../../style/base.scss";
  @import "../../../style/iconfont.css";
  @import "../../../style/afterSalesList.scss";

  .storeName {
    color: #333;

    &:hover {
      color: $colorMain;
    }
  }
</style>
<style lang="scss">
  .sld_after_sales_list{
        .el-dialog__header{
          background-color: $colorMain;
          padding: 11px 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .el-dialog__title{
            color: #fff;
            font-size: 14px;
          }
          .el-dialog__headerbtn{
            top: auto;
          }
          .el-dialog__headerbtn .el-dialog__close{
            color: #fff !important;
            font-size: 18px;
          }
        }
        .el-dialog{
          overflow: hidden;
          border-radius: 4px;
          padding: 0;
        }
        .el-dialog__footer{
          display: flex;
          justify-content: center;
          align-items: center;
        }
       .aft_btn:hover{
        background-color: rgba($color: #F30213, $alpha: 0.2);
        color: #F30213;
       }
       .aft_btn:focus{
        background-color: #fff;
        color: #333333;
        border-color: #dcdfe6;
       }
    }
</style>