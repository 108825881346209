<template>
  <div class="sld_diy">
    <div class="sld_login" v-if="!store.state.loginFlag">
      <div class="sld_login_contents">
        <div class="login">
          <div class="login_box">
            <!-- <div class="login_type flex_row_center_center">
            <div class="flex_row_center_center" :class="{'login_type_p':loginTypeo==1}" @click="login_typeo(1)">
              个人登录
            </div>
            <div class="flex_row_center_center" :class="{'login_type_p':loginTypeo==2}" @click="login_typeo(2)">
              企业登录
            </div>
          </div> -->
            <!-- <div class="qrLogin" @click="changeQrlog">
              <img class="qrLeft" :src="QRcodeLogin?idCode:qrCode">
              <img v-if="QRcodeLogin" style="width:110px;" class="qrRight" :src="idLogin">
              <img v-else class="qrRight" :src="qrLogin">
          </div> -->
            <template v-if="!QRcodeLogin">
              <div class="top">
                <!-- <div :class="{item:true,active:loginType == 1}" @click="changeLoginType(1)">{{L['账号登录']}}</div> -->
                <div
                  :class="{ item: true, active: loginType == 2 }"
                  @click="changeLoginType(2)"
                >
                  {{ L["验证码登录"] }}
                </div>
              </div>
              <div class="center">
                <!-- <template v-if="loginType == 1">
                <div class="item account">
                  <span style="color: #BBB;font-size: 19px;padding-top: 7px" class="icon iconfont icon-wode"></span>
                  <input type="text" v-model="name" :placeholder="L['请输入账号/手机号']" class="input" />
                  <div data-type="userName" class="cancel" @click="clearInputVal('name')">
                    <span style="color: #bbb;" class="iconfont icon-cuowu"></span>
                  </div>
                </div>
                <div class="item password">
                  <span style="color: #BBB;font-size: 21px;padding-top: 7px" class="icon iconfont icon-mima1"></span>
                  <input type="password" v-model="password" :placeholder="L['请输入密码']" class="input" />
                  <div data-type="password" class="cancel" @click="clearInputVal('password')">
                    <span style="color: #bbb" class="iconfont icon-cuowu"></span>
                  </div>
                </div>
              </template> -->
                <template v-if="loginType == 2">
                  <!-- <div class="item account" v-if="loginTypeo==2">
                  <span style="color: #BBB;font-size: 21px;padding-top: 7px" class="icon">
                    <img src="@/assets/building.png" alt="" style="width: 18px;height: 18px;">
                  </span>
                  <input type="text" v-model="enterpriseName" placeholder="请输入企业名称" class="input" />
                  <div data-type="enterpriseName" class="cancel" @click="clearInputVal('enterpriseName')">
                    <span style="color: #bbb;" class="iconfont icon-cuowu"></span>
                  </div>
                </div> -->
                  <div class="item account">
                    <span
                      style="color: #bbb; font-size: 21px; padding-top: 7px"
                      class="icon iconfont icon-shouji2"
                    ></span>
                    <input
                      type="text"
                      v-model="name"
                      :placeholder="L['请输入手机号']"
                      class="input"
                    />
                    <div
                      data-type="userName"
                      class="cancel"
                      @click="clearInputVal('name')"
                    >
                      <span
                        style="color: #bbb"
                        class="iconfont icon-cuowu"
                      ></span>
                    </div>
                  </div>
                  <div class="item password">
                    <span
                      style="color: #bbb; font-size: 18px; padding-top: 8px"
                      class="icon iconfont icon-yanzhengma2"
                    ></span>
                    <input
                      type="text"
                      v-model="password"
                      :placeholder="L['请输入验证码']"
                      class="input"
                    />
                    <a
                      href="javascript:void(0);"
                      class="send_code"
                      @click="getSmsCode"
                      >{{
                        countDownM ? countDownM + L["s后获取"] : L["获取验证码"]
                      }}</a
                    >
                  </div>
                  <div class="login_tips" v-if="loginFlag == 1">
                    <img src="@/assets/sigh.png" alt="" class="sigh" />
                    <p class="login_tips_p">请使用多蒙达系统帐号登录</p>
                  </div>
                </template>
                <div class="error">
                  <span
                    v-if="errorMsg"
                    style="color: #e1251b; font-size: 14px"
                    class="iconfont icon-jubao"
                  ></span>
                  {{ errorMsg }}
                </div>
                <a href="javascript:void(0)" @click="login" class="login_btn">{{
                  L["登录"]
                }}</a>
                <a
                  href="javascript:void(0)"
                  @click="flagLogin = true"
                  class="login_dmd"
                  v-if="loginFlag == 2"
                >
                  <img src="@/assets/duomengde_login.png" alt="" />
                  <p>多蒙达用户登录</p>
                </a>
              </div>
              <div class="agree_wrap">
                <input
                  type="checkbox"
                  :class="{ checkbox: true, default: true, checked: agreeFlag }"
                />
                <span
                  class="agree_selected iconfont icon-finish"
                  @click="agree"
                />
                <span class="text">
                  我同意<router-link
                    tag="a"
                    class="agreement"
                    :to="`/agreement`"
                    >《用户注册协议》</router-link
                  ><router-link
                    tag="a"
                    class="agreement"
                    :to="`/agreement?code=privacy_policy`"
                    >《隐私政策》</router-link
                  >
                </span>
              </div>
            </template>

            <template v-else>
              <div class="qrMain">
                <div class="qrMain_code">
                  <div
                    class="qrMain_code_bg"
                    :style="`filter:blur(${
                      QRout ? '1px' : '0'
                    });background-image:url(${QRlogShow});background-size:112% 112%;background-position:center;`"
                  ></div>
                  <div v-if="QRout" class="qrMain_code_cover">
                    <p>二维码已失效</p>
                    <div @click="reCreateQRcode">点击刷新</div>
                  </div>
                  <div v-else-if="QRsuccess" class="qrMain_code_cover">
                    <p class="qrMain_code_cover_p">登录成功</p>
                  </div>
                </div>
                <p class="qrMain_title">打开手机商城，扫描二维码</p>
                <div class="qrMain_tips">
                  <div class="qrMain_tips_item">
                    <span
                      style="color: #999999; font-size: 16px"
                      class="icon iconfont icon-zhuce-mianshuru"
                    ></span>
                    <span>免输入</span>
                  </div>
                  <div class="qrMain_tips_item">
                    <span
                      style="color: #999999; font-size: 16px"
                      class="icon iconfont icon-genganquan"
                    ></span>
                    <span>反应快</span>
                  </div>
                  <div class="qrMain_tips_item">
                    <span
                      style="color: #999999; font-size: 16px"
                      class="icon iconfont icon-a-kuaijin1"
                    ></span>
                    <span>更安全</span>
                  </div>
                </div>
              </div>
            </template>
            <div
              :class="{
                bottom: true,
                flex_row_between_center: wxEnable == 1,
                flex_row_end_center: wxEnable != 1 && loginFlag != 2,
                bottom_padd: loginFlag == 2,
                flex_row_between_center: loginFlag == 2,
                flex_row_between_center: wxEnable == 1
              }"
            >
              <!-- <img src="../../../assets/wechat_login.png" alt="" @click="wetChatLogin" v-if="wxEnable==1&&loginFlag==1"> -->
              <div
                class="memb_login"
                v-if="loginFlag == 1"
                @click="go_dmd"
                style="margin-left: -16px"
              >
                普通登录
              </div>

              <div style="display: flex">
                <a href="javascript:void(0)" @click="goToPage('/register')">{{
                  L["立即注册"]
                }}</a>
                <!-- <router-link tag="a" :to="`/pwd/forget`">{{L['忘记密码']}}</router-link> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-for="(item, index) in decorateData.data" v-bind:key="index">
      <el-carousel
        v-if="item.json_data && item.json_data.type == 'main_banner'"
        height="457px"
        arrow="never"
      >
        <el-carousel-item
          v-for="(item_banner, index_banner) in item.json_data.data"
          :key="index_banner"
        >
          <a href="javascript:void(0)" @click="diyNavTo(item_banner)">
            <!-- <img v-bind:src="item_banner.imgUrl" /> -->
            <div :style="`background-image:url(${item_banner.imgUrl})`"></div>
          </a>
        </el-carousel-item>
      </el-carousel>
      <!-- adv_01 start-->
      <div
        class="adv_01_wrap"
        v-if="item.json_data && item.json_data.type == 'adv_01'"
        v-bind:style="{ height: item.json_data.data.height + 'px' }"
      >
        <a href="javascript:void(0)" @click="diyNavTo(item.json_data.data)">
          <img class="adv_01_img" v-bind:src="item.json_data.data.imgUrl" />
        </a>
      </div>
      <!-- adv_01 end-->

      <!-- adv_02 start-->
      <div
        class="adv_02_part"
        v-if="item.json_data && item.json_data.type == 'adv_02'"
      >
        <div class="adv_02_left" @click="diyNavTo(item.json_data.left.data)">
          <a
            href="javascript:void(0)"
            v-if="item.json_data.left.data.imgUrl != undefined"
          >
            <img v-bind:src="item.json_data.left.data.imgUrl" />
          </a>
        </div>
        <div class="adv_02_center">
          <span class="split_h"></span>
          <div
            class="goods_item clearfix"
            v-for="(item_center, index_center) in item.json_data.center.data
              .goods_data"
            v-bind:key="index_center"
            @click="goGoodsDetail(item_center)"
          >
            <a href="javascript:void(0)">
              <div class="left">
                <img v-bind:src="item_center.goodsMainImage" />
              </div>
              <div class="right">
                <p class="goods_name">{{ item_center.skuName }}</p>
                <!-- <p class="buy_num">{{item_center.actualSales*1+item_center.virtualSales*1}}{{L['人购买']}}</p> -->
                <div class="price">
                  <span class="unit">&yen;</span>
                  <span class="integer">
                    {{ item_center.goodsPrice }}
                  </span>
                </div>
                <a class="buy" href="javascript:void(0)">{{ L["立即抢购"] }}</a>
              </div>
            </a>
          </div>
          <span class="split_v"></span>
        </div>
        <div class="adv_02_right">
          <span
            class="right_img_item"
            v-for="(item_right, index_right) in item.json_data.right.data"
            v-bind:key="index_right"
            @click="diyNavTo(item_right)"
          >
            <a href="javascript:void(0)">
              <img v-bind:src="item_right.imgUrl" />
            </a>
          </span>
        </div>
      </div>
      <!-- adv_02 end-->

      <!-- adv_04 start-->
      <div
        class="w_sld_react_1210 adv_04_wrap"
        v-if="item.json_data && item.json_data.type == 'adv_04'"
      >
        <div class="floor_title">
          <h2>
            <span
              v-bind:style="{
                backgroundColor: item.json_data.title_info.title_color
              }"
              >&nbsp;</span
            >
            <span
              v-bind:style="{ color: item.json_data.title_info.title_color }"
              >{{ item.json_data.title_info.title_name }}</span
            >
            <span
              v-bind:style="{
                backgroundColor: item.json_data.title_info.title_color
              }"
              >&nbsp;</span
            >
          </h2>
        </div>
        <div class="floor_goods">
          <div
            class="item"
            v-for="(item_main, index_main) in item.json_data.data.goods_data"
            v-bind:key="index_main"
          >
            <div class="wrap" @click="goGoodsDetail(item_main)">
              <a href="javascript:void(0)" class="example_text">
                <img v-bind:src="item_main.goodsMainImage" />
              </a>
              <p class="title">
                <a href="javascript:void(0)" title>{{ item_main.skuName }}</a>
              </p>
              <p class="price">
                <span>
                  ¥
                  <span class="money_number">{{ item_main.goodsPrice }}</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- adv_04 end-->

      <!-- adv_05 start-->
      <div
        class="w_sld_react_1210 adv_05_wrap"
        v-if="item.json_data && item.json_data.type == 'adv_05'"
      >
        <div class="floor">
          <div class="floor_layout">
            <div>
              <div class="floor_title">
                <h2>
                  <span class="main_title">{{
                    item.json_data.title_info.title.initialValue
                  }}</span>
                  <span class="sub_title">{{
                    item.json_data.title_info &&
                    item.json_data.title_info.sub_title.initialValue
                  }}</span>
                </h2>
                <div
                  class="right_action"
                  v-if="item.json_data.title_info.link_type"
                >
                  <a @click="diyNavTo(item.json_data.title_info)">
                    {{ L["查看更多"] }}
                    <i>&gt;&gt;</i>
                  </a>
                </div>
              </div>

              <div class="floor_content">
                <div class="floor_left">
                  <div class="floor_bg_img">
                    <a
                      class="ad_img example_text"
                      href="javascript:void(0)"
                      @click="diyNavTo(item.json_data.left.data)"
                    >
                      <img v-bind:src="item.json_data.left.data.imgUrl" />
                    </a>
                  </div>
                  <div class="floor_words">
                    <div class="floor_words_top_title">
                      <span></span>
                      <span>{{
                        item.json_data.left.cat_data.title_info.title_name
                      }}</span>
                      <span></span>
                    </div>
                    <ul>
                      <li
                        v-for="(item_left_cat, index_left_cat) in item.json_data
                          .left.cat_data.cat_datas"
                        v-bind:key="index_left_cat"
                      >
                        <a
                          href="javascript:void(0)"
                          title="item_left_cat.categoryName"
                          @click="goGoodsListByCatId(item_left_cat.categoryId)"
                          >{{ item_left_cat.categoryName }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="floor_right">
                  <div class="floor_right_main">
                    <div class="floor_content">
                      <div
                        class="big_item item"
                        v-if="item.json_data.center.data.goods_data.length"
                      >
                        <div
                          class="wrap"
                          @click="
                            goGoodsDetail(
                              item.json_data.center.data.goods_data[0]
                            )
                          "
                        >
                          <div class="left_pic">
                            <a
                              href="javascript:void(0)"
                              class="ad_img example_text"
                            >
                              <img
                                v-bind:src="
                                  item.json_data.center.data.goods_data[0]
                                    .goodsMainImage
                                "
                                alt="item.json_data.center.data.goods_data[0].goodsName"
                              />
                            </a>
                          </div>
                          <div class="right_info">
                            <p class="title">
                              <a
                                href="javascript:void(0)"
                                title="item.json_data.center.data.goods_data[0].goodsName"
                                >{{
                                  item.json_data.center.data.goods_data[0]
                                    .skuName
                                }}</a
                              >
                            </p>
                            <p class="price">
                              ¥
                              <span class="money_number">{{
                                item.json_data.center.data.goods_data[0]
                                  .goodsPrice
                              }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        class="item"
                        v-if="item.json_data.center.data.goods_data.length > 1"
                      >
                        <div
                          class="wrap"
                          @click="
                            goGoodsDetail(
                              item.json_data.center.data.goods_data[1]
                            )
                          "
                        >
                          <a class="ad_img" href="javascript:void(0)">
                            <img
                              v-bind:src="
                                item.json_data.center.data.goods_data[1]
                                  .goodsMainImage
                              "
                              alt="item.json_data.center.data.goods_data[1].goodsName"
                            />
                          </a>
                          <p class="title">
                            <a
                              href="javascript:void(0)"
                              title="item.json_data.center.data.goods_data[1].goodsName"
                              >{{
                                item.json_data.center.data.goods_data[1].skuName
                              }}</a
                            >
                          </p>
                          <p class="price">
                            ¥
                            <span class="money_number">{{
                              item.json_data.center.data.goods_data[1]
                                .goodsPrice
                            }}</span>
                          </p>
                        </div>
                      </div>
                      <template
                        v-for="(item_center_bottom, index_center_bottom) in item
                          .json_data.center.data.goods_data"
                      >
                        <div
                          class="item bottom_item"
                          :key="index_center_bottom"
                          v-if="index_center_bottom > 1"
                          @click="goGoodsDetail(item_center_bottom)"
                        >
                          <div class="wrap">
                            <a
                              href="javascript:void(0)"
                              class="ad_img example_text"
                            >
                              <img
                                v-bind:src="item_center_bottom.goodsMainImage"
                                alt="item_center_bottom.goodsName"
                              />
                            </a>
                            <p class="title">
                              <a
                                href="javascript:void(0)"
                                title="item_center_bottom.goodsName"
                                >{{ item_center_bottom.skuName }}</a
                              >
                            </p>
                            <p class="price">
                              ¥
                              <span class="money_number">{{
                                item_center_bottom.goodsPrice
                              }}</span>
                            </p>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="floor_right_new">
                    <div class="floor_right_new_top_title">
                      <span style="background: #fc585a"></span>
                      <span style="color: #fc585a">{{
                        item.json_data.right.title_info.title_name
                      }}</span>
                      <span style="background: #fc585a"></span>
                    </div>
                    <div class="floor_content">
                      <div
                        class="item"
                        v-for="(item_right_goods, index_right_goods) in item
                          .json_data.right.data.goods_data"
                        v-bind:key="index_right_goods"
                      >
                        <div
                          class="wrap"
                          @click="goGoodsDetail(item_right_goods)"
                        >
                          <div class="left_pic">
                            <a
                              href="javascript:void(0)"
                              class="ad_img example_text"
                            >
                              <img
                                v-bind:src="item_right_goods.goodsMainImage"
                                alt="item_right_goods.skuName"
                              />
                            </a>
                          </div>
                          <div class="right_info">
                            <p class="title">
                              <a
                                href="javascript:void(0)"
                                title="item_right_goods.skuName"
                                >{{ item_right_goods.skuName }}</a
                              >
                            </p>
                            <p class="price">
                              ¥
                              <span class="money_number">{{
                                item_right_goods.goodsPrice
                              }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- adv_05 end-->

      <!-- adv_06 start-->
      <div
        v-if="item.json_data && item.json_data.type == 'adv_06'"
        class="adv_06 com_1210_center"
      >
        <div class="adv_06_wrap">
          <div
            class="item"
            v-for="(item_child, index_child) in item.json_data.data"
            v-bind:key="index_child"
          >
            <a href="javascript:void(0)" @click="diyNavTo(item_child)">
              <img v-bind:src="item_child.imgUrl" />
            </a>
          </div>
        </div>
      </div>
      <!-- adv_06 end-->

      <!-- adv_07 start-->
      <div
        class="w_sld_react_1210 adv_07"
        v-if="item.json_data && item.json_data.type == 'adv_07'"
      >
        <div class="adv_07_wrap">
          <div
            class="item"
            v-for="(item_child, index_child) in item.json_data.data"
            :key="index_child"
          >
            <a
              href="javascript:void(0);"
              @click="diyNavTo(item_child)"
              v-if="item_child.imgUrl"
            >
              <img :src="item_child.imgUrl" />
            </a>
            <a class="show_tip" href="javascript:void(0);" v-else>
              <span>此处添加【403*高度不限】图片</span>
            </a>
          </div>
        </div>
      </div>
      <!-- adv_07 end-->

      <!-- adv_08 start-->
      <div
        class="w_sld_react_1210 adv_08 com_1210_center"
        v-if="item.json_data && item.json_data.type == 'adv_08'"
      >
        <div class="adv_08_wrap">
          <div
            class="item"
            v-for="(item_main, index_main) in item.json_data.data"
            v-bind:key="index_main"
            @click="diyNavTo(item_main)"
          >
            <a href="javascript:void(0)">
              <img v-bind:src="item_main.imgUrl" />
            </a>
          </div>
        </div>
      </div>
      <!-- adv_08 end-->

      <!-- adv_09 start-->
      <div
        class="adv_09 com_1210_center"
        v-if="item.json_data && item.json_data.type == 'adv_09'"
      >
        <div class="adv_09_wrap">
          <div class="item left">
            <div
              class="top_title"
              v-bind:style="{
                backgroundColor: item.json_data.left.title_info.title_bg_color,
                color: item.json_data.left.title_info.title_color
              }"
            >
              {{ item.json_data.left.title_info.title_name }}
            </div>

            <div class="main_con">
              <a
                class="first show_tip"
                href
                v-for="(item_left, index_left) in item.json_data.left.data"
                v-bind:key="index_left"
                @click="diyNavTo(item_left)"
              >
                <img v-bind:src="item_left.imgUrl" />
              </a>
            </div>
          </div>
          <div class="item center">
            <div
              class="top_title"
              v-bind:style="{
                backgroundColor:
                  item.json_data.center.title_info.title_bg_color,
                color: item.json_data.center.title_info.title_color
              }"
            >
              {{ item.json_data.center.title_info.title_name }}
            </div>
            <div class="main_con">
              <a
                class="show_tip"
                href
                v-for="(item_center, index_center) in item.json_data.center
                  .data"
                v-bind:key="index_center"
                @click="diyNavTo(item_center)"
              >
                <img v-bind:src="item_center.imgUrl" />
              </a>
            </div>
          </div>
          <div class="item right">
            <div
              class="top_title"
              v-bind:style="{
                backgroundColor: item.json_data.right.title_info.title_bg_color,
                color: item.json_data.right.title_info.title_color
              }"
            >
              {{ item.json_data.right.title_info.title_name }}
            </div>
            <div class="main_con">
              <a
                class="show_tip"
                href
                v-for="(item_right, index_right) in item.json_data.right.data"
                v-bind:key="index_right"
                @click="diyNavTo(item_right)"
              >
                <img v-bind:src="item_right.imgUrl" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- adv_09 end-->

      <!-- adv_10 start-->
      <div
        class="adv_10 com_1210_center"
        v-if="item.json_data && item.json_data.type == 'adv_10'"
      >
        <div class="adv_10_wrap">
          <div
            class="row_one"
            v-for="(item_one, index_one) in item.json_data.row_one.data"
            v-bind:key="index_one"
          >
            <a href="javascript:void(0)" @click="diyNavTo(item_one)">
              <img v-bind:src="item_one.imgUrl" />
            </a>
          </div>
          <div class="row_four">
            <a
              href="javascript:void(0)"
              v-for="(item_four, index_four) in item.json_data.row_four.data"
              v-bind:key="index_four"
              @click="diyNavTo(item_four)"
            >
              <img v-bind:src="item_four.imgUrl" />
            </a>
          </div>
          <div class="row_five">
            <a
              href="javascript:void(0)"
              v-for="(item_five, index_five) in item.json_data.row_five.data"
              v-bind:key="index_five"
              @click="diyNavTo(item_five)"
            >
              <img v-bind:src="item_five.imgUrl" />
            </a>
          </div>
        </div>
      </div>
      <!-- adv_10 end-->

      <!-- adv_11 start-->
      <div
        class="adv_11 com_1210_center"
        v-if="item.json_data && item.json_data.type == 'adv_11'"
      >
        <div class="adv_11_wrap">
          <div class="item row_left">
            <a
              href="javascript:void(0)"
              v-for="(item_left, index_left) in item.json_data.row_left.data"
              v-bind:key="index_left"
              v-bind:class="{ lb_margin: index_left % 2 == 1 }"
              @click="diyNavTo(item_left)"
            >
              <img v-bind:src="item_left.imgUrl" />
            </a>
          </div>
          <div class="item row_right">
            <div class="top">
              <a
                href="javascript:void(0)"
                v-if="item.json_data.row_right.top.data[0] != undefined"
                @click="diyNavTo(item.json_data.row_right.top.data[0])"
              >
                <img v-bind:src="item.json_data.row_right.top.data[0].imgUrl" />
              </a>
            </div>
            <div class="bottom">
              <a
                href="javascript:void(0)"
                v-if="item.json_data.row_right.bottom.data[0] != undefined"
                @click="diyNavTo(item.json_data.row_right.bottom.data[0])"
              >
                <img
                  v-bind:src="item.json_data.row_right.bottom.data[0].imgUrl"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- adv_11 end-->

      <!-- adv_12 start-->
      <div
        class="adv_12 com_1210_center"
        v-if="item.json_data && item.json_data.type == 'adv_12'"
      >
        <div class="adv_12_wrap">
          <div class="item left clear_padding">
            <a
              class="l_img"
              href="javascript:void(0)"
              @click="diyNavTo(item.json_data.left.data[0])"
            >
              <img v-bind:src="item.json_data.left.data[0].imgUrl" />
            </a>
          </div>
          <div class="item center">
            <a
              v-for="(item_center, index_center) in item.json_data.center.data"
              v-bind:key="index_center"
              v-bind:class="{ l_b_margin: index_center % 2 == 1 }"
              @click="diyNavTo(item_center)"
            >
              <img v-bind:src="item_center.imgUrl" />
            </a>
          </div>
          <div class="item right">
            <div
              class="title_wrap"
              @click="diyNavTo(item.json_data.right.title_info)"
            >
              <a class="title" href="javascript:void(0)">{{
                item.json_data.right.title_info.title.initialValue
              }}</a>
              <span> 》</span>
              <a href="javascript:void(0)" class="subtitle">{{
                item.json_data.right.title_info.sub_title.initialValue
              }}</a>
            </div>
            <div class="img_top">
              <a
                href="javascript:void(0)"
                @click="diyNavTo(item.json_data.right.top.data[0])"
              >
                <img v-bind:src="item.json_data.right.top.data[0].imgUrl" />
              </a>
            </div>
            <div class="img_bottom">
              <a
                class="l_img"
                href="javascript:void(0)"
                v-for="(item_right_bottom, index_right_bottom) in item.json_data
                  .right.bottom.data"
                v-bind:key="index_right_bottom"
                @click="diyNavTo(item_right_bottom)"
              >
                <img v-bind:src="item_right_bottom.imgUrl" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- adv_12 end-->

      <!-- adv_13 start-->
      <div
        class="w_sld_react_1210 adv_13 com_1210_center"
        v-if="item.json_data && item.json_data.type == 'adv_13'"
      >
        <div class="adv_13_wrap">
          <div
            class="item right"
            @click="diyNavTo(item.json_data.left.title_info)"
          >
            <div class="title_wrap">
              <a class="title" href="javascript:void(0)">{{
                item.json_data.left.title_info.title.initialValue
              }}</a>
              <span> 》</span>
              <a class="subtitle" href="javascript:void(0)">{{
                item.json_data.left.title_info.sub_title.initialValue
              }}</a>
            </div>
            <div class="img_top">
              <a
                class
                href="javascript:void(0)"
                @click="diyNavTo(item.json_data.left.top.data[0])"
              >
                <img v-bind:src="item.json_data.left.top.data[0].imgUrl" />
              </a>
            </div>
            <div class="img_bottom">
              <a
                class
                href="javascript:void(0)"
                v-for="(item_left_bottom, index_left_bottom) in item.json_data
                  .left.bottom.data"
                v-bind:key="index_left_bottom"
                v-bind:class="{ l_b_margin: index_left_bottom % 2 == 0 }"
                @click="diyNavTo(item_left_bottom)"
              >
                <img v-bind:src="item_left_bottom.imgUrl" />
              </a>
            </div>
          </div>
          <div class="item right">
            <div
              class="title_wrap"
              @click="diyNavTo(item.json_data.center.title_info)"
            >
              <a class="title" href="javascript:void(0)">{{
                item.json_data.center.title_info.title.initialValue
              }}</a>
              <span> 》</span>
              <a class="subtitle" href="javascript:void(0)">{{
                item.json_data.center.title_info.sub_title.initialValue
              }}</a>
            </div>
            <div class="img_top">
              <a
                href="javascript:void(0)"
                @click="diyNavTo(item.json_data.center.top.data[0])"
              >
                <img v-bind:src="item.json_data.center.top.data[0].imgUrl" />
              </a>
            </div>
            <div class="img_bottom">
              <a
                class="l_img"
                href="javascript:void(0)"
                v-for="(item_center_bottom, index_center_bottom) in item
                  .json_data.center.bottom.data"
                v-bind:key="index_center_bottom"
                @click="diyNavTo(item_center_bottom)"
              >
                <img v-bind:src="item_center_bottom.imgUrl" />
              </a>
            </div>
          </div>
          <div class="item left clear_padding">
            <a
              class="l_img"
              href="javascript:void(0)"
              @click="diyNavTo(item.json_data.right.data[0])"
            >
              <img :src="item.json_data.right.data[0].imgUrl" />
            </a>
          </div>
        </div>
      </div>
      <!-- adv_13 end-->

      <!-- adv_19 start-->
      <div
        class="w_sld_react_1210 adv_19"
        v-if="item.json_data && item.json_data.type == 'adv_19'"
      >
        <div class="adv_19_wrap">
          <div
            v-for="(items, index) in item.json_data.data"
            :key="index"
            class="item left"
          >
            <div
              class="top_title"
              :style="{ backgroundColor: items.top.title_info.title_bg_color }"
            >
              <div
                class="l_title"
                :style="{ color: items.top.title_info.title_color }"
              >
                {{
                  items.top.title_info.title_name
                    ? items.top.title_info.title_name
                    : "添加标题"
                }}
              </div>
              <div class="r_title">
                <ul>
                  <li
                    v-for="(val, key) in items.center.right"
                    :key="key"
                    :class="{ sel_tab: key == items.cur_tab }"
                    @mouseover="adv19Tab(item.dataId, index, key)"
                  >
                    <span class="con">
                      {{
                        val.title_info.title_name
                          ? val.title_info.title_name
                          : "添加内容"
                      }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="center">
              <div class="l_center">
                <a
                  href="javascript:;"
                  @click="diyNavTo(items.center.left.data[0])"
                >
                  <img
                    v-if="items.center.left.data[0].imgUrl"
                    :src="items.center.left.data[0].imgUrl"
                  />
                  <span v-else>此处添加【186*340】图片</span>
                </a>
              </div>
              <div class="r_center">
                <div class="tabs_panel">
                  <div
                    v-for="(val, key) in items.center.right[items.cur_tab].data"
                    :key="key"
                    class="item"
                  >
                    <div class="title_wrap">
                      <a
                        class="main_title"
                        href="javascript:;"
                        @click="diyNavTo(val)"
                      >
                        {{ val.main_title ? val.main_title : "图片标题" }}
                      </a>
                      <a
                        class="sub_title"
                        href="javascript:;"
                        @click="diyNavTo(val)"
                      >
                        {{ val.sub_title ? val.sub_title : "图片子标题" }}
                      </a>
                    </div>

                    <div class="bottom_img">
                      <a
                        class="sld_com_no_img"
                        href="javascript:;"
                        @click="diyNavTo(val)"
                      >
                        <img v-if="val.imgUrl" :src="val.imgUrl" />
                        <span v-else>此处添加【172*106】图片</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="bottom">
              <a
                v-for="(val, key) in items.bottom.data"
                :key="key"
                class="sld_com_no_img"
                href="javascript:;"
                @click="diyNavTo(val)"
              >
                <img v-if="val.imgUrl" :src="val.imgUrl" />
                <span v-else>此处添加【187*120】图片</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- adv_19 end-->

      <!-- adv_20 start-->
      <div
        class="w_sld_react_1210 adv_20"
        v-if="item.json_data && item.json_data.type == 'adv_20'"
      >
        <div class="adv_20_wrap flex_column_start_center">
          <div
            class="flex_row_around_center adv_20_wrap_row"
            v-for="(val, key) in item.json_data.data"
            :key="key"
          >
            <div
              v-for="(child, child_key) in val"
              :key="child_key"
              class="flex_column_center_center adv_20_wrap_item"
            >
              <div
                class="flex_row_center_center adv_20_wrap_item_img"
                style="border-radius: 0px"
                @click="diyNavTo(child)"
              >
                <img :src="child.imgUrl" />
              </div>
              <span class="main_title" @click="diyNavTo(child)">{{
                child.main_title
              }}</span>
              <span class="sub_title" @click="diyNavTo(child)">{{
                child.sub_title
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- adv_20 end-->

      <!-- adv_21 start-->
      <div
        class="w_sld_react_1210 adv_21"
        v-if="item.json_data && item.json_data.type == 'adv_21'"
      >
        <div class="adv_21_wrap flex_row_start_start">
          <div
            class="flex_column_start_start adv_21_wrap_item"
            v-for="(val1, key1) in item.json_data.data"
            :key="key1"
          >
            <div
              class="flex_row_between_center title_part"
              @click="diyNavTo(val1.top_title)"
            >
              <span class="title">{{ val1.top_title.title.initialValue }}</span>
              <a class="view_more"
                >{{ val1.top_title.sub_title.initialValue }}&gt;</a
              >
            </div>
            <div class="flex_column_start_start detail">
              <div
                class="flex_row_start_start item"
                v-for="(val2, key2) in val1.detail"
                :key="key2"
              >
                <div class="item_left" @click="diyNavTo(val2.left)">
                  {{ val2.left.title.initialValue }}
                </div>
                <div class="flex_row_start_center item_right">
                  <span
                    class="item_right_con"
                    v-for="(val3, key3) in val2.right"
                    :key="key3"
                    @click="diyNavTo(val3)"
                  >
                    {{ val3.title.initialValue }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- adv_21 end-->
    </div>
    <!-- 弹框 -->
    <el-dialog
      v-model="flagLogin"
      width="460px"
      class="dialogs"
      :show-close="false"
      top="30vh"
    >
      <div class="flagLogin_box flex_row_center_center">
        <img src="../assets/md_error.png" alt="" />
        是否允许授权多蒙达账号登录
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="go_dmd">确定</el-button>
          <el-button @click="close" class="aft_btn">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { ref, getCurrentInstance, onMounted, watch } from "vue";
import { useRouter, onBeforeRouteLeave, useRoute } from "vue-router";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { platform } from "@/utils/config";
export default {
  name: "SldDiy",
  props: {
    decorateData: Object, //装修的数据
    source: String //来源，home:平台首页  store:店铺装修
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const isShow = ref(true);
    const flagLogin = ref(false); //显示确授权框
    const keyEnter = ref(true);
    const errorMsg = ref(); //错误提示
    const name = ref(""); //用户名
    const enterpriseName = ref(""); //企业名称
    const password = ref(""); //密码
    const loginType = ref(2); //登陆类型：1-账号密码登陆，2-手机验证码登陆
    const clickSmscode = ref(false); //是否获取验证码中
    const countDownM = ref(0); //短信验证码倒计时
    const timeOutId = ref(""); //定时器的返回值
    const agreeFlag = ref(false); //同意注册协议标识，默认不同意
    const configInfo = ref(store.state.configInfo);
    const gongcaiLogo = ref(require("../assets/gongcaiLogo.jpg"));
    const jicaiLogo = ref(require("../assets/jicaiLogo.jpg"));
    const gongcaiBg = ref(require("../assets/gongcaiBg.png"));
    const jicaiBg = ref(require("../assets/jicaiBg.png"));
    const defaultImg = ref(
      'this.src="' + require("../assets/common_top_logo.png") + '"'
    );
    const defaultBgImg = ref(
      'this.src="' + require("../assets/login_bg.png") + '"'
    );
    const fromurl = ref("");
    const wxEnable = ref("");
    const pcAppid = ref("");
    const loginFlag = ref("2"); //多蒙达和普通登录切换标识
    const loginTypeo = ref("1"); //个人登录和企业登录
    const shipperUserPhone = ref("");
    const companyName = ref("");
    const companyCode = ref("");

    /* 二维码登录 */
    const QRlogShow = ref("");
    const QRissend = ref(null); //二维码登录定时器
    const QRcheck = ref(null); //二维码循环定时器
    const QRsuccess = ref(false); //二维码登录成功
    const QRout = ref(false); //二维码是否过期
    const QRcodeLogin = ref(false); //二维码登录
    const qrCode = require("../assets/qrCode.png");
    const qrLogin = require("../assets/qrLogin.png");
    const idCode = require("../assets/idCode.png");
    const idLogin = require("../assets/idLogin.png");
    /* 二维码登录 */

    //由于这里的回车键触发事件和商品搜索框的回车键触发事件冲突，引入keyEnter变量判断
    document.onkeydown = function () {
      var key = window.event.keyCode;
      if (key == 13 && keyEnter.value) {
        login();
      }
    };
    // 多蒙达和普通登录切换
    const go_dmd = () => {
      if (loginFlag.value == 1) {
        loginFlag.value = 2;
      } else {
        loginFlag.value = 1;
      }
      flagLogin.value = false;
      errorMsg.value = "";
    };

    // 授权弹框关闭
    const close = () => {
      flagLogin.value = false;
    };
    const login = () => {
      let param = {};
      param.username = name.value;
      param.password = password.value;
      param.loginType = loginType.value;
      param.type = loginFlag.value;
      if (param.loginType == 1) {
        //账号、手机号的验证
        if (!param.username) {
          errorMsg.value = L["请输入账号/手机号"];
          return false;
        } else {
          let checkName = proxy.$checkMemberName(param.username);
          if (checkName !== true) {
            //继续验证是否是手机号
            let checkMobile = proxy.$checkPhone(param.username);
            if (checkMobile !== true) {
              errorMsg.value = L["请输入正确的账号/手机号"];
              return false;
            }
          }
        }

        //密码校验
        if (!param.password) {
          errorMsg.value = L["请输入密码"];
          return false;
        } else {
          let checkPwd = proxy.$checkPwd(param.password);
          if (checkPwd !== true) {
            errorMsg.value = checkPwd;
            return false;
          }
        }
      } else {
        if (!agreeFlag.value) {
          errorMsg.value = "请同意用户注册协议及隐私政策";
          return false;
        }
        //手机号验证
        if (!param.username) {
          errorMsg.value = L["请输入手机号"];
          return false;
        } else {
          let checkMobile = proxy.$checkPhone(param.username);
          // if (checkMobile !== true) {
          //   errorMsg.value = checkMobile;
          //   return false;
          // }
        }

        //验证码校验
        if (!param.password) {
          errorMsg.value = L["请输入短信验证码"];
          return false;
        } else {
          let checkSmsCode = proxy.$checkSmsCode(param.password);
          if (checkSmsCode !== true) {
            errorMsg.value = checkSmsCode;
            return false;
          }
        }
      }
      //如果未登录vuex中有购物车数据，需同步到该用户
      let cartInfo = [];
      console.log(store.state);
      let cartListData = store.state.cartListData;
      if (JSON.stringify(cartListData) != "{}") {
        cartListData.cartGoods.map((promotItem) => {
          promotItem.goodsCartGoodsRespList.map((cartItem) => {
            cartInfo.push({
              productId: cartItem.spuCode,
              buyNum: cartItem.goodsCount
            });
          });
        });
      }
      // param.cartInfo = JSON.stringify(cartInfo);
      param.cartInfo = [];
      proxy.$post("v3/frontLogin/oauth/token", param).then((res) => {
        if (res.state == 200) {
          //将用户信息存缓存，并跳转到首页
          if (res.data.memberType && res.data.memberType == "审核失败") {
            router.push({
              path: "/registrationReview",
              query: {
                memberId: res.data.memberId,
                reason: res.data.reason
              }
            });
          } else if (res.data.memberType && res.data.memberType == "审核中") {
            ElMessage(res.data.memberType);
          } else {
            localStorage.setItem("access_token", res.data.access_token);
            localStorage.setItem("refresh_token", res.data.refresh_token);
            localStorage.setItem("time", new Date().getTime().toString()); //存储refresh_token更新时间
            //获取用户信息，并同步信息到vuex
            proxy.$get("/v3/member/front/member/getInfo").then((res) => {
              if (res.state == 200) {
                store.commit("updateMemberInfo", res.data);
                proxy.$getLoginCartListData(); //更新购物车数据
                if (QRcodeLogin.value) {
                  clearTimeout(QRissend.value);
                  clearInterval(QRcheck.value);
                  QRissend.value = null;
                  QRcheck.value = null;
                }
                //   if (window.history.state.back) {
                //     router.back();
                //   } else {
                router.replace({ path: "/member/index" });
                //   }
              }
            });
          }
        } else {
          //提示错误
          errorMsg.value = res.msg;
        }
      });
    };
    //清空输入框内容
    const clearInputVal = (type) => {
      if (type == "name") {
        name.value = "";
      } else if (type == "password") {
        password.value = "";
      } else if (type == "enterpriseName") {
        enterpriseName.value = "";
      }
    };
    //登录方式切换
    const changeLoginType = (type) => {
      loginType.value = type;
      name.value = "";
      password.value = "";
      errorMsg.value = "";
    };
    //获取短信验证码
    const getSmsCode = () => {
      if (clickSmscode.value) {
        return;
      } else if (countDownM.value) {
        return false;
      }
      clickSmscode.value = true;
      let checkMobile = proxy.$checkPhone(name.value);
      if (checkMobile !== true) {
        errorMsg.value = checkMobile;
        clickSmscode.value = false;
      } else {
        let param = {};
        param.mobile = name.value;
        param.type = "free";
        proxy.$get("v3/msg/front/commons/smsCode", param).then((res) => {
          if (res.state == 200) {
            countDownM.value = 120;
            countDown();
            clickSmscode.value = false;
          } else {
            //提示错误
            errorMsg.value = res.msg;
            clickSmscode.value = false;
          }
        });
      }
    };
    //倒计时
    const countDown = () => {
      countDownM.value--;
      if (countDownM.value == 0) {
        clearTimeout(timeOutId.value);
      } else {
        timeOutId.value = setTimeout(countDown, 1000);
      }
    };
    //通过replace方式跳转页面
    const goToPage = (type) => {
      router.replace({
        path: type
      });
    };

    // 微信二维码登录
    const wetChatLogin = () => {
      let href = `https://open.weixin.qq.com/connect/qrconnect?appid=${
        pcAppid.value
      }&redirect_uri=${encodeURIComponent(
        fromurl.value
      )}&response_type=code&scope=snsapi_login&state=2or3m#wechat_redirect`;
      window.open(href, "_blank");
    };

    const getWxEnbale = () => {
      proxy
        .$get("v3/system/front/setting/getSettings", {
          names: "login_wx_pc_is_enable,login_wx_pc_appid"
        })
        .then((res) => {
          if (res.state == 200) {
            wxEnable.value = res.data[0];
            pcAppid.value = res.data[1];
          }
        });
    };

    // 切换账号密码/二维码登录
    const changeQrlog = () => {
      QRcodeLogin.value = !QRcodeLogin.value;
      if (QRcodeLogin.value && !QRissend.value) {
        createQRcode();
      }
    };

    // 刷新二维码
    const reCreateQRcode = () => {
      setTimeout(() => {
        QRout.value = false;
        createQRcode();
      }, 200);
    };

    // 生成二维码 (过期定时器、获取扫码状态定时器)
    const createQRcode = () => {
      proxy.$get("v3/member/front/qr/login/createQr").then((res) => {
        /* 二维码图片 */
        QRlogShow.value = "data:image/png;base64," + res.data.image;
        // console.log(QRlogShow.value)
        /* 过期定时器 */
        QRissend.value = setTimeout(() => {
          console.log("-----二维码过期-----");
          clearTimeout(QRissend.value);
          clearInterval(QRcheck.value);
          QRissend.value = null;
          QRcheck.value = null;
          QRout.value = true;
        }, 300000);
        /* ------ end ------ */

        let param = {
          u: res.data.u
        };
        /* 获取扫码状态定时器 */
        QRcheck.value = setInterval(() => {
          proxy
            .$post("v3/member/front/qr/login/progress", param)
            .then((result) => {
              // console.log(result.data);
              if (result.data.progress == 2) {
                console.log("-----授权登录成功-----");
                QRsuccess.value = true;
                clearTimeout(QRissend.value);
                clearInterval(QRcheck.value);
                QRissend.value = null;
                QRcheck.value = true;

                localStorage.setItem("refresh_token", result.data.refreshToken);
                /* 更新 access_token */
                let params = new FormData();
                params.append("grant_type", "refresh_token");
                params.append("refresh_token", result.data.refreshToken);
                proxy
                  .$post("v3/frontLogin/oauth/token", params, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                      Authorization: "Basic ZnJvbnQ6ZnJvbnQ="
                    }
                  })
                  .then(
                    (response) => {
                      if (response.state == 200) {
                        localStorage.setItem(
                          "access_token",
                          response.data.access_token
                        );
                        localStorage.setItem(
                          "time",
                          new Date().getTime().toString()
                        ); //存储refresh_token更新时间

                        /* 获取用户信息，并同步信息到vuex */
                        proxy
                          .$get("/v3/member/front/member/getInfo")
                          .then((res_info) => {
                            if (res_info.state == 200) {
                              store.commit("updateMemberInfo", res_info.data);
                              proxy.$getLoginCartListData(); //更新购物车数据
                              if (window.history.state.back) {
                                router.back();
                              } else {
                                router.replace({ path: "/member/index" });
                              }
                            }
                          });
                        /* ------ end ------ */
                      }
                    },
                    (err) => {
                      console.log("更新access_token出错：", err);
                    }
                  );
                /* ------ end ------ */
              }
            });
        }, 3000);
        /* ------ end ------ */
      });
    };

    watch([name, password], () => {
      if (loginType.value == 1) {
        password.value = password.value.substring(0, 20);
        name.value = name.value.substring(0, 20);
      } else {
        password.value = password.value.substring(0, 6);
        name.value = name.value.substring(0, 11);
      }

      if (password.value || name.value) {
        errorMsg.value = "";
      }
    });
    //是否同意用户注册协议
    const agree = () => {
      console.log(agreeFlag.value);
      agreeFlag.value = !agreeFlag.value;
    };
    // 个人和企业切换
    const login_typeo = (num) => {
      loginTypeo.value = num;
    };

    // 单点登录
    const getLogin_o = () => {
      let param = {};
      param.shipperUserPhone = shipperUserPhone.value;
      if (companyName.value) {
        param.companyName = companyName.value;
      }
      if (companyCode.value) {
        param.companyCode = companyCode.value;
      }
      proxy.$post("v3/frontLogin/oauth/accessToken", param).then((res) => {
        if (res.state == 200) {
          //将用户信息存缓存，并跳转到首页
          localStorage.setItem("access_token", res.data.access_token);
          localStorage.setItem("refresh_token", res.data.refresh_token);
          localStorage.setItem("time", new Date().getTime().toString()); //存储refresh_token更新时间
          //获取用户信息，并同步信息到vuex
          proxy.$get("/v3/member/front/member/getInfo").then((res) => {
            if (res.state == 200) {
              store.commit("updateMemberInfo", res.data);
              proxy.$getLoginCartListData(); //更新购物车数据
              if (QRcodeLogin.value) {
                clearTimeout(QRissend.value);
                clearInterval(QRcheck.value);
                QRissend.value = null;
                QRcheck.value = null;
              }
              if (window.history.state.back) {
                router.back();
              } else {
                router.replace({ path: "/member/index" });
              }
            }
          });
          countDownM.value = 120;
          countDown();
        } else {
          //提示错误
          errorMsg.value = res.msg;
        }
      });
    };
    onMounted(() => {
      isShow.value = true;
      getWxEnbale();
      if (route.query.redirectUrl) {
        fromurl.value =
          window.location.origin + decodeURIComponent(route.query.redirectUrl);
      } else {
        fromurl.value = window.location.origin;
      }

      if (route.query.error) {
        ElMessage.error(decodeURIComponent(route.query.error));
      }
      if (route.query.shipperUserPhone) {
        isShow.value = false;
        shipperUserPhone.value = route.query.shipperUserPhone;
        if (route.query.companyName) {
          companyName.value = route.query.companyName;
        }
        if (route.query.companyCode) {
          companyCode.value = route.query.companyCode;
        }
        getLogin_o();
      }
    });

    onBeforeRouteLeave(() => {
      keyEnter.value = false;
    });
    const diyNavTo = (val) => {
      if (route.query.vid) {
        val.storeId = route.query.vid;
      }
      proxy.$diyNavTo(val);
    };
    const goGoodsDetail = (val) => {
      proxy.$goGoodsDetail(val.skuCode);
    };
    const goGoodsListByCatId = (val) => {
      proxy.$goGoodsListByCatId(val);
    };
    //adv_19楼层tab切换事件
    const adv19Tab = (dataId, index, key) => {
      emit("adv19", {
        dataId: dataId,
        left_or_right_index: index,
        tab_index: key
      });
    };
    return {
      L,
      goGoodsDetail,
      diyNavTo,
      adv19Tab,
      goGoodsListByCatId,
      store,
      L,
      agree,
      agreeFlag,
      loginType,
      login,
      name,
      password,
      clearInputVal,
      errorMsg,
      changeLoginType,
      getSmsCode,
      clickSmscode,
      countDownM,
      goToPage,
      configInfo,
      gongcaiLogo,
      jicaiLogo,
      platform,
      defaultImg,
      defaultBgImg,
      gongcaiBg,
      jicaiBg,
      wetChatLogin,
      wxEnable,
      QRcodeLogin,
      QRissend,
      QRout,
      qrCode,
      qrLogin,
      idCode,
      idLogin,
      changeQrlog,
      reCreateQRcode,
      createQRcode,
      QRlogShow,
      QRcheck,
      QRsuccess,
      go_dmd,
      loginFlag,
      flagLogin,
      close,
      loginTypeo,
      login_typeo,
      enterpriseName,
      shipperUserPhone,
      companyName,
      companyCode,
      isShow
    };
  }
};
</script>
<style lang="scss" scoped>
@import "../style/mixins.scss";
@import "../style/decorate.scss";
@import "../style/login.scss";

.sld_login {
  position: absolute;
  right: 30px;
  top: -70px;
  .el-dialog {
    overflow: hidden;
    border-radius: 4px;
  }
  .el-dialog__footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .aft_btn {
    margin-left: 49px;
  }
  .el-button {
    width: 100px;
  }
  .aft_btn:hover {
    background-color: rgba($color: $colorMain, $alpha: 0.2);
    color: #f30213;
  }
  .aft_btn:focus {
    background-color: #fff;
    color: #333333;
    border-color: #dcdfe6;
  }
  .flagLogin_box {
    text-align: center;
    padding-top: 30px;
    img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }
}
.agree_wrap {
  margin-bottom: 11px;
  height: 14px;
  line-height: 14px;
  color: #999;
  padding-left: 26px;
  position: relative;
  cursor: pointer;
  .agree_selected {
    color: #fff;
    position: absolute;
    top: 0px;
    left: 26px;
    z-index: 2;
    font-size: 13px;
  }

  .checkbox {
    width: 14px;
    height: 14px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    outline: none;
    -webkit-appearance: none;
    background: none;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    box-shadow: none;

    &.checked {
      &:before {
        background: $colorMain;
        border-color: $colorMain;
      }
    }

    &:before {
      border: 1px solid #ddd;
      background: #fff;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      content: " ";
      display: block;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }
  }

  .text {
    margin-left: 5px;
    display: inline-block;
    vertical-align: top;

    .agreement {
      color: #000;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.sld_diy {
  position: relative;
  .el-carousel {
    width: 100%;
    margin: 0 auto;
  }

  background: #f8f8f8;

  .el-carousel__item {
    /* width: 1920px;
            /*图片宽度*/
    /* position: absolute;
            left: 50%;
            margin-left: -960px; */
    /* 图片宽度的一半 */
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      background-position: center center;
      width: 1920px;
      height: 457px;
      position: absolute;
      left: 50%;
      top: 0;
      margin-left: -960px;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .main_banner {
    width: 100%;
    height: 457px;
    position: relative;
    overflow: hidden;
    margin: 0 auto;

    .nav-menu {
      position: absolute;
      left: 50%;
      margin-left: -600px;
      top: -3px;
      width: 187px;
      height: 457px;
      z-index: 9;
      background-color: rgba(0, 0, 0, 0.6);
      -webkit-box-sizing: border-box;
      box-sizing: border-box;

      .menu-wrap {
        .menu-item {
          height: 39px;
          line-height: 39px;
          margin: 5px 0;
          position: relative;

          .first_cat {
            width: 125px;
            display: block;
            font-size: 16px;
            color: #ffffff;
            padding-left: 30px;
            text-overflow: ellipsis;
            overflow: hidden;
            position: relative;

            .nav-menu {
              position: absolute;
              left: 50%;
              margin-left: -600px;
              top: -3px;
              width: 187px;
              height: 457px;
              z-index: 9;
              background-color: rgba(0, 0, 0, 0.6);
              -webkit-box-sizing: border-box;
              box-sizing: border-box;

              .menu-wrap {
                .menu-item {
                  height: 39px;
                  line-height: 39px;
                  margin: 5px 0;

                  .first_cat {
                    width: 125px;
                    display: block;
                    font-size: 16px;
                    color: #ffffff;
                    padding-left: 30px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    position: relative;

                    &:after {
                      position: absolute;
                      right: 0px;
                      top: 12.5px;
                      content: " ";
                      width: 7px !important;
                      height: 14px !important;
                      @include bgImg(7px, 14px, "/imgs/arrow_right.png");
                    }
                  }

                  &:hover {
                    background-color: $colorMain;

                    .children {
                      display: inline-block;
                    }
                  }

                  .children {
                    display: none;
                    width: 1013px;
                    height: 417px;
                    background-color: #fff;
                    position: absolute;
                    top: 0;
                    left: 187px;
                    padding: 20px 0;

                    .fore-dl {
                      width: 100%;
                      clear: both;
                      overflow: hidden;
                    }

                    dt {
                      position: relative;
                      float: left;
                      width: 152px;
                      padding: 8px 30px 0 0;
                      text-align: right;
                      font-weight: 700;
                      line-height: 2em;
                      overflow: hidden;
                      white-space: nowrap;

                      i {
                        position: absolute;
                        top: 11px;
                        right: 18px;
                        width: 4px;
                        height: 14px;
                        font: 400 9px/14px consolas;
                      }

                      .second {
                        color: #333;
                        font-size: 14px;
                        width: 140px;
                        overflow: hidden;
                        display: inline-block;
                        line-height: 18px;
                        text-overflow: ellipsis;
                      }
                    }

                    dd {
                      border-top: none;
                      border-bottom: 1px dashed #dddddd;
                      width: 790px;
                      padding: 6px 0;
                      float: left;
                      line-height: 16px;
                      margin-left: 15px;
                    }

                    .third {
                      float: left;
                      padding: 0 8px;
                      margin: 4px 0;
                      line-height: 16px;
                      height: 16px;
                      border-right: 1px solid #e0e0e0;
                      white-space: nowrap;
                      color: #999;
                      font-size: 13px;
                    }

                    .children_item {
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-start;
                      align-items: flex-start;
                      flex-wrap: wrap;
                      height: 30px;

                      .li_wrap {
                        height: 30px;
                        line-height: 30px;
                        flex: 1;
                        padding-left: 23px;

                        a {
                          line-height: 30px;
                          height: 30px;
                          color: #999999;
                        }
                      }

                      a {
                        color: $colorMain;
                        font-size: 14px;
                      }

                      img {
                        width: 42px;
                        height: 35px;
                        vertical-align: middle;
                        margin-right: 15px;
                      }
                    }
                  }
                }
              }
            }
          }

          &:hover {
            background-color: $colorMain;

            .children {
              display: inline-block;
            }
          }

          .children {
            display: none;
            width: 1013px;
            height: 417px;
            background-color: #fff;
            position: absolute;
            top: 0;
            left: 187px;
            padding: 20px 0;

            .fore-dl {
              width: 100%;
              clear: both;
              overflow: hidden;
            }

            dt {
              position: relative;
              float: left;
              width: 152px;
              padding: 8px 30px 0 0;
              text-align: right;
              font-weight: 700;
              line-height: 2em;
              overflow: hidden;
              white-space: nowrap;

              i {
                position: absolute;
                top: 11px;
                right: 18px;
                width: 4px;
                height: 14px;
                font: 400 9px/14px consolas;
              }

              .second {
                color: #333;
                font-size: 14px;
                width: 140px;
                overflow: hidden;
                display: inline-block;
                line-height: 18px;
                text-overflow: ellipsis;
              }
            }

            dd {
              border-top: none;
              border-bottom: 1px dashed #dddddd;
              width: 790px;
              padding: 6px 0;
              float: left;
              line-height: 16px;
              margin-left: 15px;
            }

            .third {
              float: left;
              padding: 0 8px;
              margin: 4px 0;
              line-height: 16px;
              height: 16px;
              border-right: 1px solid #e0e0e0;
              white-space: nowrap;
              color: #999;
              font-size: 13px;
            }

            .children_item {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: flex-start;
              flex-wrap: wrap;
              height: 30px;

              .li_wrap {
                height: 30px;
                line-height: 30px;
                flex: 1;
                padding-left: 23px;

                a {
                  line-height: 30px;
                  height: 30px;
                  color: #999999;
                }
              }

              a {
                color: $colorMain;
                font-size: 14px;
              }

              img {
                width: 42px;
                height: 35px;
                vertical-align: middle;
                margin-right: 15px;
              }
            }
          }
        }
      }
    }

    .swiper-container {
      width: 100%;
      height: 100%;
      position: absolute;

      .swiper-slide {
        a {
          display: inline-block;
          width: 100%;
          height: 100%;

          img {
            width: 1920px;
            height: 100%;
            margin-left: -960px;
            left: 50%;
            position: relative;
            overflow: hidden;
          }
        }
      }
    }
  }

  .main_diy {
    margin-top: 10px;
  }

  .product-box {
    background-color: $colorJ;
    padding: 30px 0 50px;

    h2 {
      font-size: $fontF;
      height: 21px;
      line-height: 21px;
      color: $colorMain;
      margin-bottom: 20px;
    }

    .wrapper {
      display: flex;

      .banner-left {
        margin-right: 16px;

        img {
          width: 224px;
          height: 619px;
        }
      }

      .list-box {
        .list {
          @include flex();
          width: 986px;
          margin-bottom: 14px;

          &:last-child {
            margin-bottom: 0;
          }

          .item {
            width: 236px;
            height: 302px;
            background-color: $colorG;
            text-align: center;

            span {
              display: inline-block;
              width: 67px;
              height: 24px;
              font-size: 14px;
              line-height: 24px;
              color: $colorG;

              &.new-pro {
                background-color: #7ecf68;
              }

              &.kill-pro {
                background-color: #e82626;
              }
            }

            .item-img {
              img {
                width: 100%;
                height: 195px;
              }
            }

            .item-info {
              h3 {
                font-size: 14px;
                color: $colorMain;
                line-height: 14px;
                font-weight: bold;
              }

              p {
                color: $colorD;
                line-height: 13px;
                margin: 6px auto 13px;
              }

              .price {
                color: #f20a0a;
                font-size: 14px;
                font-weight: bold;
                cursor: pointer;

                &:after {
                  @include bgImg(22px, 22px, "/imgs/icon-cart-hover.png");
                  content: " ";
                  margin-left: 5px;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
