<!-- //购物车页面 -->
<template>
  <div class="sld_cart_index">
    <div class="cart_content" v-if="
        cartData.data &&
        ((cartData.data.invalidList && cartData.data.invalidList.length > 0) ||
          (cartData.data.cartGoods &&
            cartData.data.cartGoods.length > 0))
      ">
      <!-- 购物车列表顶部标题 start-->
      <div class="cart_title flex_row_start_center">
        <div class="cart_title_pre flex_row_start_center" v-if="cartData.data.cartGoods.length > 0">
          <img src="@/assets/cart/sel.png" alt="" v-if="cartData.data.checkedAll" @click="changeSelectState('all', cartData.data.checkedAll)" />
          <img src="@/assets/cart/no_sel.png" alt="" v-if="!cartData.data.checkedAll" @click="changeSelectState('all', cartData.data.checkedAll)" />
          <span>{{ L["全选"] }}</span>
        </div>
        <div class="cart_title_pre">
          <span>{{ L["商品信息"] }}</span>
        </div>
        <div class="cart_title_pre">
          <span>{{ L["单价"] }}</span>
        </div>
        <div class="cart_title_pre">
          <span>{{ L["数量"] }}</span>
        </div>
        <div class="cart_title_pre">
          <span>{{ L["小计（元）"] }}</span>
        </div>
        <div class="cart_title_pre">
          <span>{{ L["操作"] }}</span>
        </div>
      </div>
      <!-- 购物车列表顶部标题 end-->
      <!-- 购物车列表 start -->
      <div class="cart_lists" v-show="cartData.data">
        <div class="cart_lists_pre" v-for="(cartGroupItem, cartGroupIndex) in cartData.data.cartGoods" :key="cartGroupIndex">
          <!-- 店铺信息 start -->
          <div class="store_info flex_row_start_center">
            <!-- 可选择 -->
            <img src="@/assets/cart/sel.png" alt="" class="store_sel"
              v-if="cartGroupItem.lackAll" @click="
                changeSelectState(
                  'store',
                  cartGroupItem.lackAll,
                  cartGroupItem
                )
              " />
            <img src="@/assets/cart/no_sel.png" alt="" class="store_sel"
              v-if="!cartData.data.checkedAll && !cartGroupItem.lackAll" @click="
                changeSelectState(
                  'store',
                  cartGroupItem.lackAll,
                  cartGroupItem
                )
              " />
            <!-- 全部无货，不可选择 -->
            <img src="@/assets/cart/no_sel.png" alt="" class="store_sel" v-if="cartGroupItem.checkedAll" />
            <div target="_blank" class="store_des flex_row_start_center cursor_pointer">
              <span v-if="cartGroupItem.orderBelongs!==2">{{platform==1?'大石工采':'大石集采'}}</span>
              <span v-if="cartGroupItem.orderBelongs==2">{{cartGroupItem.userName}}</span>
              <img src="@/assets/cart/right_down.png" alt="" />
            </div>
            <!-- <router-link target="_blank" :to="`/store/index?vid=${cartGroupItem.userId}`" class="store_des flex_row_start_center cursor_pointer">
              <span>{{ cartGroupItem.userName }}</span>
              <span>大石工采</span>
              <img src="@/assets/cart/right_down.png" alt="" />
            </router-link> -->
            <div class="get_coupons_btn flex_row_center_center cursor_pointer" v-show="cartGroupItem.hasCoupon"
              @click="openCouponModel(cartGroupItem.storeId)">
              <span>{{ L["领取优惠券"] }}</span>
              <img src="@/assets/cart/right_down_red.png" alt="" />
            </div>
          </div>
          <!-- 店铺信息 end -->
          <!-- 商品信息 start -->
          <div class="goods_con">
            <!-- 满减活动 start -->
            <div class="full_reduc_activity flex_row_start_center" v-show="cartGroupItem.promotionDes">
              <span class="full_reduc_title">{{ L["满减"] }}</span>
              <div class="full_reduc_des" v-html="cartGroupItem.promotionDes"></div>
              <!-- <div class="go_collect_bill">{{ L["再逛逛"] }}></div> -->
              <!-- <div class="go_collect_bill">{{L['去凑单']}}></div> -->
            </div>
            <!-- 满减活动 end -->
            <!-- 商品列表 start -->
            <div class="goods_list">
              <div class="goods_pre flex_row_start_center"
                v-for="(cartItem, cartIndex) in cartGroupItem.goodsCartGoodsRespList" :key="cartIndex">
                <!-- 可选择 1-正常状态，2-商品失效（已删除），3-商品无货-->
                <div class="goods_pre_sel">
                  <img src="@/assets/cart/sel.png" alt="" class="store_sel"
                    v-if="cartItem.isChecked && cartItem.productState != 3" @click="
                      changeSelectState('goods', cartItem.isChecked, cartItem)
                    " />
                  <img src="@/assets/cart/no_sel.png" alt="" class="store_sel"
                    v-if="!cartItem.isChecked && cartItem.productState != 3" @click="
                      changeSelectState('goods', cartItem.isChecked, cartItem)
                    " />
                  <!-- 不可选择 无货-->
                  <img src="@/assets/cart/no_sel.png" alt="" class="store_sel" v-if="cartItem.productState == 3" />
                </div>

                <!-- 商品信息 -->
                <div :style="{
                    backgroundImage: 'url(' + cartItem.marqueImage + ')',
                  }" class="goods_img" :class="{ no_stock_img: cartItem.productState == 3 }"
                  @click="goGoodsDetail(cartItem.skuCode)"></div>
                <div class="goods_des" @click="goGoodsDetail(cartItem.skuCode)">
                  <p class="goods_name" :class="{ no_stock_name: cartItem.productState == 3 }">
                    {{ cartItem.skuName }}
                  </p>
                  <p class="goods_spec" :class="{ no_stock_spec: cartItem.productState == 3 }">
                    {{ cartItem.marque }}
                  </p>
                </div>
                <!-- 单价 -->
                <div class="goods_price_prom">
                  <div class="goods_price" :class="{ no_stock_price: cartItem.productState == 3 }">
                    ￥{{ cartItem.goodsPrice }}
                  </div>
                  <div v-if="cartItem.promotionList && cartItem.promotionList.length > 0">
                    <el-popover width="300px" v-model:visible="cartItem.promotionModel" @show="promotionShow(cartItem)">
                      <template #reference>
                        <div v-if="cartItem.promotionList.length > 0" class="promotion cursor_pointer">
                          {{ L["促销"]
                          }}<i class="iconfont icon-ziyuan11-copy"></i>
                        </div>
                      </template>
                      <el-radio-group v-model="curPromotion" @change="changePromotion($event, cartItem)"
                        text-color="#333333" fill="#FF0000">
                        <el-radio v-for="(
                            promotionItem, promotionIndex
                          ) in cartItem.promotionList" :key="promotionIndex" :label="promotionItem.promotionId"
                          class="promotion_radio">
                          <p v-html="promotionItem.promotionDes"></p>
                        </el-radio>
                      </el-radio-group>
                      <div class="promotion_btn flex_row_center_center">
                        <span @click="selPromotion()">{{ L["确定"] }}</span>
                        <span @click="cancelPromotion()">{{ L["取消"] }}</span>
                      </div>
                    </el-popover>
                  </div>
                </div>
                <!-- 数量 -->
                <div class="goods_num flex_column_center_center">
                  <div class="goods_edit_nem flex_row_center_center">
                    <span @click="editNum('reduce', cartItem)">-</span>
                    <input type="number" v-model="cartItem.goodsCount" @input="editNum('edit', cartItem, $event)"
                      @blur="editNum('blur', cartItem, $event)"
                      @keyup="handleKey" />
                    <span @click="editNum('add', cartItem)">+</span>
                  </div>
                  <div class="goods_stocks" v-if="cartItem.productState != 3">
                    {{ L["库存"] }} {{ cartItem.goodsStock }}
                  </div>
                  <div class="goods_stocks goods_stocks_no" v-if="cartItem.productState == 3">
                    {{ L["库存不足"] }}
                  </div>
                  <div class="goods_stocks goods_stocks_red" v-if="cartItem.goodsCount > cartItem.goodsStock">
                    {{ L["剩余"] }} {{ cartItem.goodsStock }} {{ L["件"] }}
                  </div>
                </div>
                <!-- 小计 -->
                <div class="subtotal">￥{{cartItem.goodsCount?((Math.ceil((Number(cartItem.goodsCount*10000)*cartItem.goodsPrice*100)/1000))/1000).toFixed(2):(Math.ceil(cartItem.goodsPrice*100)/100).toFixed(2)}}</div>
                <!-- 操作 -->
                <div class="goods_btn flex_column_center_start">
                  <!-- <span class="goods_collect" @click="moveCartGoods(cartItem.skuCode)" v-if="token">{{L["移入收藏夹"]}}</span> -->
                  <el-popconfirm :title="L['确定删除选中商品？']" @confirm="delCartGoods('goods', cartItem)"
                    :confirmButtonText="L['确定']" :cancelButtonText="L['取消']">
                    <template #reference>
                      <span class="goods_del">{{ L["删除"] }}</span>
                    </template>
                  </el-popconfirm>
                </div>
              </div>
            </div>
            <!-- 商品列表 end -->
          </div>
          <!-- 商品信息 end -->
        </div>
        <!-- 失效商品 start -->
        <div class="invalid_goods" v-if="cartData.data.invalidList && cartData.data.invalidList.length > 0">
          <div class="invalid_goods_title">{{ L["失效商品"] }}</div>
          <div class="invalid_goods_list">
            <div class="invalid_goods_pre flex_row_start_center"
              v-for="(invalidItem, invalidIndex) in cartData.data.invalidList" :key="invalidIndex">
              <span class="invalid_tips">{{ L["失效"] }}</span>
              <div :style="{
                  backgroundImage: 'url(' + invalidItem.marqueImage + ')',
                }" class="invalid_img"></div>
              <div class="invalid_des">
                <p class="invalid_name">{{ invalidItem.skuName }}</p>
                <p class="invalid_spec">{{ invalidItem.boxSpec }}</p>
              </div>
              <div class="invalid_price">￥{{ invalidItem.goodsCount?(Number(invalidItem.goodsCount)*invalidItem.goodsPrice).toFixed(2):invalidItem.goodsPrice.toFixed(2) }}</div>
              <div class="invalid_num flex_column_center_center">
                <div class="invalid_edit_num flex_row_center_center">
                  <span>-</span>
                  <input type="number" disabled v-model="invalidItem.goodsCount" />
                  <span>+</span>
                </div>
                <!-- <div class="invalid_off">{{ L["已下架"] }}</div> -->
              </div>
              <div class="invalid_subtotal">￥{{ invalidItem.goodsCount?(Number(invalidItem.goodsCount)*invalidItem.goodsPrice).toFixed(2):invalidItem.goodsPrice.toFixed(2) }}</div>
              <div class="invalid_btn flex_column_center_start">
                <!-- <span class="invalid_collect" @click="moveCartGoods(invalidItem.spuCode)" v-if="token">{{ L["移入收藏夹"]}}</span> -->
                <span class="invalid_del" @click="delCartGoods('goods', invalidItem)">{{ L["删除"] }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 失效商品 -->
      </div>
      <!-- 购物车列表 end -->
      <!-- 操作按钮 start -->
      <div class="options_btn flex_row_between_center" ref="optionsBtn">
        <div class="options_btn_left flex_row_start_center">
          <div class="options_sel" v-if="cartData.data.cartGoods.length > 0">
            <img src="@/assets/cart/sel.png" alt="" class="store_sel" v-if="cartData.data.checkedAll"
              @click="changeSelectState('all', cartData.data.checkedAll)" />
            <img src="@/assets/cart/no_sel.png" alt="" class="store_sel" v-if="!cartData.data.checkedAll"
              @click="changeSelectState('all', cartData.data.checkedAll)" />
            <span>{{ L["全选"] }}</span>
          </div>
          <div class="del_all cursor_pointer" @click="delBatchGoods" v-if="cartData.data.cartGoods.length > 0">
            {{ L["删除选中的商品"] }}
          </div>
          <div class="del_all_invalid cursor_pointer" @click="clearInvalidGoods"
            v-if="token&&cartData.data.invalidList.length > 0">
            {{ L["清空失效的商品"] }}
          </div>
        </div>
        <div class="options_right flex_row_start_center">
          <div class="options_sel_num">
            {{ L["已选择"] }}{{ cartData.data.totalCheck }}{{ L["件商品"] }}
          </div>
          <div class="options_line"></div>
          <div class="options_sel_price flex_row_start_center">
            <div class="options_all_count flex_column_start_end">
              <span>{{ L["合计"] }}：</span>
              <span v-if="cartData.data.totalDiscount">{{ L["促销减"] }}：</span>
            </div>
            <div class="options_promotion flex_column_start_end">
              <span>￥{{ cartData.data.totalAmount }}</span>
              <span v-if="cartData.data.totalDiscount">￥{{ cartData.data.totalDiscount }}</span>
            </div>
          </div>
          <div class="go_buy cursor_pointer" :class="{ active: cartData.data.totalAmount > 0}" @click="goBuy">
            {{ L["去结算"] }}
          </div>
        </div>
      </div>
      <!-- 操作按钮 end -->
    </div>
    <div class="cart_content_no flex_column_center_center" v-show="
        !cartData.data ||
        JSON.stringify(cartData.data) == '{}' ||
        (cartData.data &&
          cartData.data.invalidList &&
          cartData.data.invalidList.length == 0 &&
          cartData.data.cartGoods &&
          cartData.data.cartGoods.length == 0)
      ">
      <img src="@/assets/cart/no_data.png" alt="" />
      <p>{{ L["这里空空如也，快去首页逛逛吧~"] }}</p>
      <span @click="goIndex">{{ L["去首页 > >"] }}</span>
    </div>
    <!-- 清空失效商品 弹框 start -->
    <el-dialog title="提示" v-model="clearInvalidGoodsModel" width="20%" center top="30vh">
      <div style="margin:0 auto; text-align: center">{{ L["确定清空失效商品？"] }}</div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancelClearInvalidModel">{{
            L["取 消"]
            }}</el-button>
          <el-button type="primary" @click="confirmClearInvalidModel">{{
            L["确 定"]
            }}</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 清空失效商品 弹框 end -->

    <!-- 删除所选商品 弹框 start -->
    <el-dialog title="提示" v-model="chosenGoodsModel" width="20%" center top="30vh">
      <div style="margin:0 auto; text-align: center">{{ L['确定删除选中商品？']}}</div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="chosenGoodsModel = false">{{L["取 消"]}}</el-button>
          <el-button type="primary" @click="delCartGoods('batch')">{{L["确 定"]}}</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 删除所选商品 弹框 end -->

    <!-- 优惠券弹框 start -->
    <el-dialog v-model="couponModel" title="优惠券" width="832px">
      <div class="coupon_model">
        <div class="model_coupon_list">
          <CouponItem v-for="(couponItem, couponIndex) in couponList.data.list" :key="couponIndex"
            :coupon_item="couponItem" :class="sld_coupon_item" @refreshCouponList="openCouponModel"></CouponItem>
        </div>
        <div class="flex_row_end_center sld_pagination sld_page_bottom"
          v-if="couponList.data.pagination && couponList.data.pagination.total">
          <el-pagination @current-change="handleCurrentChangeCoupon" v-model:currentPage="curCouponPage"
            :page-size="couponPageSize" layout="prev, pager, next, jumper" :total="couponList.data.pagination.total"
            :hide-on-single-page="true">
          </el-pagination>
        </div>
      </div>
    </el-dialog>
    <!-- 优惠券弹框 end -->

    <!-- 推荐商品 start -->
    <recommend-list></recommend-list>
    <!-- 推荐商品 end -->

    <!-- 登录弹框 start -->
    <SldLoginModal :visibleFlag="loginModalVisibleFlag" @closeLoingModal="closeLoingModal" />
    <!-- 登录弹框 end -->

    <!-- 底部操作悬浮框 start -->
    <div class="bottom_options" v-if="optionsBtnShow">
      <div class="bottom_options_con flex_row_between_center">
        <div class="options_btn_left flex_row_start_center">
          <div class="options_sel">
            <img src="@/assets/cart/sel.png" alt="" class="store_sel" v-if="cartData.data.checkedAll"
              @click="changeSelectState('all', cartData.data.checkedAll)" />
            <img src="@/assets/cart/no_sel.png" alt="" class="store_sel" v-if="!cartData.data.checkedAll"
              @click="changeSelectState('all', cartData.data.checkedAll)" />
            <span>{{ L["全选"] }}</span>
          </div>
          <div class="del_all cursor_pointer" @click="delBatchGoods">
            {{ L["删除选中的商品"] }}
          </div>
          <div class="del_all_invalid cursor_pointer" @click="clearInvalidGoods"
            v-if="token&&cartData.data.invalidList.length > 0">
            {{ L["清空失效的商品"] }}
          </div>
        </div>
        <div class="options_right flex_row_start_center">
          <div class="options_sel_num">
            {{ L["已选择"] }}{{ cartData.data.totalCheck }}{{ L["件商品"] }}
          </div>
          <div class="options_line"></div>
          <div class="options_sel_price flex_row_start_center">
            <div class="options_all_count flex_column_start_end">
              <span>{{ L["合计"] }}：</span>
              <span v-if="cartData.data.totalDiscount">{{ L["促销减"] }}：</span>
            </div>
            <div class="options_promotion flex_column_start_end">
              <span>￥{{ cartData.data.totalAmount }}</span>
              <span v-if="cartData.data.totalDiscount">￥{{ cartData.data.totalDiscount }}</span>
            </div>
          </div>
          <div class="go_buy cursor_pointer" :class="{ active: cartData.data.totalAmount > 0 }" @click="goBuy">
            {{ L["去结算"] }}
          </div>
        </div>
      </div>


    </div>
    <!-- 底部操作悬浮框 end -->

  </div>
</template>

<script>
  import { reactive, getCurrentInstance, ref, watchEffect, onMounted } from "vue";
  import {
    ElMessage,
    ElDialog,
    ElButton,
    ElPopover,
    ElPopconfirm,
    ElMessageBox
  } from "element-plus";
  import { useRouter } from "vue-router";
  import RecommendList from "../../components/RecommendList";
  import CouponItem from "@/components/CouponItem";
  import { useStore } from "vuex";
  import SldLoginModal from "../../components/loginModal";
  import { platform } from '@/utils/config';

  export default {
    name: "CartIndex",
    components: {
      ElDialog,
      ElButton,
      ElPopover,
      ElPopconfirm,
      RecommendList,
      CouponItem,
      SldLoginModal,
    },
    setup() {
      const router = useRouter();
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const store = useStore();
      const token = ref(store.state.loginFlag); //登录缓存是否登录
      const loginModalVisibleFlag = ref(false); //登录弹框是否显示，默认不显示
      const cartData = reactive({ data: store.state.cartListData }); //购物车数据信息
      const clearInvalidGoodsModel = ref(false); //清空失效商品对话框是否显示
      const delGoodsCartIds = ref(""); //要删除的商品的购物车的id集
      const delType = ref("goods"); //要删除的商品类型：goods：单个商品删除 batch:批量删除
      const curPromotion = ref(-1); //当前选择的商品的活动的促销的id
      const curPromotionCartItem = reactive({ data: {} }); //当前选择的要换促销的商品
      const couponModel = ref(false); //优惠券弹框是否显示
      const couponList = reactive({ data: {} }); //当前店铺优惠券列表数据
      const cartListData = reactive({ data: store.state.cartListData }); //获取vux的store中的购物车数据
      const optionsBtnTop = ref(0); //底部操作按钮元素的top值
      const windowHeight = ref(0); //屏幕元素的高度
      const optionsBtnShow = ref(false); //底部操作按钮悬浮框是否显示
      const optionsBtn = ref(null); //获取底部操作按钮元素
      const curPromotionGoodsProductId = ref(''); //当前点击的促销活动的商品
      const curCouponPage = ref(1); //当前为第一页优惠券
      const couponPageSize = ref(6); //优惠券默认一页显示6条数据
      const curStoreId = ref(-1); //当前打开的优惠券弹框的店铺id
      const elementScrollTop = ref(0); //页面滚动高度
      const chosenGoodsModel = ref(false);
      const timer = ref(true);  //购物车节流

      onMounted(() => {
        window.addEventListener('scroll', scrollHandle); //绑定页面滚动事件
      });
      //页面监听token是否更新
      watchEffect(() => {
        token.value = store.state.loginFlag;
        cartData.data = store.state.cartListData;
        //实时获取屏幕的高度
        windowHeight.value = document.documentElement.clientHeight;

        if (optionsBtn.value != null && optionsBtn.value != undefined) {
          optionsBtnTop.value = optionsBtn.value.offsetTop;
          if (optionsBtnTop.value > windowHeight.value) {
            optionsBtnShow.value = true;
          } else {
            optionsBtnShow.value = false;
          }
          if (elementScrollTop.value + windowHeight.value > optionsBtnTop.value) {
            optionsBtnShow.value = false;
          } else {
            optionsBtnShow.value = true;
          }
        }
      });
      const scrollHandle = (e) => {
        elementScrollTop.value = e.srcElement.scrollingElement.scrollTop;
        // elementScrollTop.value = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      };

      //已选择商品的件数,计算合计(未登录使用)
      const colculationOperation = () => {
        if (JSON.stringify(cartData.data) != "{}" && cartData.data != undefined) {
          cartData.data.totalCheck = 0;
          cartData.data.totalAmount = 0;
          cartData.data &&
            cartData.data.cartGoods &&
            cartData.data.cartGoods.map((storeItem) => {
              storeItem.promotionCartGroupList.map((promotItem) => {
                promotItem.cartList.map((cartItem) => {
                  cartItem.subtotal = (Number(cartItem.goodsCount) * cartItem.goodsPrice).toFixed(2);
                  if (cartItem.isChecked == 1) {
                    cartData.data.totalCheck++;
                    cartData.data.totalAmount += Number(cartItem.subtotal);
                  }
                });
              });
            });
        }
      };

      //更新本地缓存购物车数据
      const updateLocCartData = (cartData) => {
        //将购物车数据更新到vuex中的store中
        store.commit("updateCartListData", cartData);
      };

      //获取购物车数据
      const getCartData = () => {
        if (store.state.loginFlag) {
          //已登录
          let params = { userId: store.state.memberInfo.memberId };
          proxy.$get("api/shopp/cart/queryCart", params).then((res) => {  //原接口：v3/business/front/cart/cartList
              if (res.code == 200) {
                cartData.data = proxy.$calculateSubtotal(res.data);
                cartData.data = proxy.$cartDataNum(res.data);
                acLabelMarkify()
                console.log(cartData.data)
              } else {
                ElMessage.error(res.msg);
              }
            })
            .then(() => {
              store.commit("updateCartListData", cartData.data); //将购物车数据存储到vuex的store中
            });
        } else {
          //未登录
          cartData.data = proxy.$calculateSubtotal(cartListData.data);
          colculationOperation();
        }
        updateLocCartData(cartData.data);
      };
      getCartData();

      /**编辑购物车商品数量
       * type:编辑类型 reduce：减  edit：编辑 add：加
       * curCartItem：编辑的当前项商品
       * e:事件对象
       */
      const editNum = (type, curCartItem, e) => {
        let curNumber = curCartItem.goodsCount;
        let flagVal = 0;
        switch (type) {
            case 'reduce': {
            flagVal = 0;
            if (curNumber > curCartItem.minNum) {
              curCartItem.goodsCount--;
              curNumber--
            } else {
              // curCartItem.goodsCount = curCartItem.minNum;
              return ElMessage(`最低限购${curCartItem.minNum }!`);
            }
            break
          }

          case 'add': {
            flagVal = 1;
            if (curNumber >= curCartItem.goodsStock) {
              ElMessage(L["数量超出购买范围！"]);
              curCartItem.goodsCount = curCartItem.goodsStock
              return
            } else {
              // if (curNumber > 998) {
              //   ElMessage(L["超过购买限制!"]);
              //   curCartItem.buyNum = 999;
              //   return
              // } else {
                curCartItem.goodsCount++;
                curNumber++
              // }
            }
            break;
          }

          case 'edit': {
            flagVal = 2;
            curNumber = e.currentTarget.value;
            // if (curNumber == 0 && curNumber != '') {
            //   curCartItem.buyNum = 1;
            //   curNumber = 1
            //   return
            // } else 
            if (curNumber == '') {
              return
            } else {
              console.log(curCartItem.caregoryIdOne)
              if(curCartItem.caregoryIdOne==12 && curNumber.toString().indexOf(".")!=-1) {
                curCartItem.goodsCount = curNumber.toString().substring(0,curNumber.toString().indexOf(".")+5);
                if (curCartItem.goodsCount > curCartItem.goodsStock) {
                  ElMessage(L["数量超出购买范围！"]);
                  curCartItem.goodsCount = curCartItem.goodsStock
                  return
                }
              }else{
                if(curCartItem.caregoryIdOne!=12){ curCartItem.goodsCount = curNumber.replace(/[^\d]/g, '') }
                // curCartItem.goodsCount = curNumber.replace(/[^\d]/g, '');
                if (curCartItem.goodsCount > curCartItem.goodsStock) {
                  ElMessage(L["数量超出购买范围！"]);
                  curCartItem.goodsCount = curCartItem.goodsStock
                  return
                }
                if (curCartItem.goodsCount < curCartItem.minNum) {
                  ElMessage(`最低限购${curCartItem.minNum }!`);
                  curCartItem.goodsCount = curCartItem.minNum
                }
              }
              // if (curNumber > 998) {
              //   ElMessage(L["超过购买限制!"]);
              //   curCartItem.buyNum = 999;
              //   return
              // }
              // curCartItem.buyNum = curNumber
            }
            break
          }

          case 'blur': {
            flagVal = 2;
            if (curNumber == '') {
              curCartItem.goodsCount = curCartItem.minNum
              return
            }
          }
        }
        curNumber = curCartItem.goodsCount
        if (store.state.loginFlag) {
          //已登录
          let params = {
            flag: flagVal,
            userId: store.state.memberInfo.memberId,
            skuCode: curCartItem.skuCode, //商品购物车id
            num: curNumber, //修改后商品数量
          };
          proxy.$get("api/shopp/cart/updateSum", params).then((res) => { //原接口：v3/business/front/cart/changeNum  POST
            if (res.code == 200) {
              if (timer.value) {
                let timerFc = setTimeout(() => {
                  // timer.value = false;
                  getCartData();
                  // cartData.data = proxy.$calculateSubtotal(res.data);
                  curCartItem.promotionModel = false
                  closePromotionModel()
                  acLabelMarkify()
                  updateLocCartData(cartData.data);
                  clearTimeout(timerFc);
                  // timer.value = false
                }, 500)
              }
            } else {
              ElMessage.error(res.msg);
            }
          });
        } else {
          //未登录
          cartData.data.cartGoods &&
            cartData.data.cartGoods.map((storeItem) => {
              storeItem.promotionCartGroupList.map((promotItem) => {

                promotItem.cartList.map((cartItem) => {
                  cartItem.promotionModel = false
                  if (curCartItem.skuCode == cartItem.skuCode) {
                    cartItem.goodsCount = curCartItem.goodsCount;
                  }
                });
              });
            });
          colculationOperation();
          updateLocCartData(cartData.data);
        }
      };

      //当前店铺及购物车的选中状态(未登录使用)
      const curStoreCartSelChecked = () => {
        let curSelStore = []; //当前店铺未选中
        cartData.data.cartGoods.map((storeItem) => {
          storeItem.promotionCartGroupList.map((promotItem) => {
            curSelStore = promotItem.cartList.filter(
              (cartItem) => cartItem.isChecked == 0
            );
            if (curSelStore.length > 0) {
              storeItem.checkedAll = false;
            } else {
              storeItem.checkedAll = true;
            }
          });
        });
        let curCartData = []; //当前购物车是否全选或全不选
        curCartData = cartData.data.cartGoods.filter(
          (storeItem) => !storeItem.checkedAll
        );
        if (curCartData.length > 0) {
          cartData.data.checkedAll = false;
        } else {
          cartData.data.checkedAll = true;
        }
      };

      /**选中状态的更改
       * type ： goods:商品， store :店铺 all,全部（全选或者全不选）
       * isCheckedState：商品：当前商品的选中状态 店铺:店铺的选中状态
       * curCartStoreGoodsId：商品：当前商品购物车信息 店铺：storeId
       */
      const changeSelectState = (type, isCheckedState, curCartStoreGoodsId) => {
        if (store.state.loginFlag) {
          //已登录
          let cartIds = ""; //商品的购物车id集
          if (type == "goods") {
            //选择商品
            cartIds = curCartStoreGoodsId.skuCode;
          } else if (type == "store") {
            //选择店铺
            cartData.data.cartGoods.map((storeItem) => {
              if (storeItem.userId == curCartStoreGoodsId.userId) {
                storeItem.goodsCartGoodsRespList.map((cartItem) => {
                    cartIds += cartItem.skuCode + ",";
                  });
              }
            });
            cartIds = cartIds.substring(0, cartIds.length - 1);
          } else if (type == "all") {
            //全选或全不选
            cartData.data.cartGoods.map((storeItem) => {
                storeItem.goodsCartGoodsRespList.map((cartItem) => {
                  cartIds += cartItem.skuCode + ",";
                });
            });
            cartIds = cartIds.substring(0, cartIds.length - 1);
          }
          let params = {
            userId: store.state.memberInfo.memberId,
            skuCodes: cartIds, //购物车id集合
            checked: isCheckedState ? 0 : 1, //是否选中：0=全不选、1=全选中
          };
          proxy.$get("api/shopp/cart/choice", params).then((res) => {  //原接口：v3/business/front/cart/checkedCarts POST
              if (res.code == 200) {
                getCartData();
                // cartData.data = res.data
                acLabelMarkify()
                // updateLocCartData(cartData.data);
                closePromotionModel();
              } else {
                ElMessage.error(res.msg);
              }
            });
        } else {
          //未登录
          if (type == "goods") {
            //单个商品
            cartData.data.cartGoods.map((storeItem) => {
              storeItem.promotionCartGroupList.map((promotItem) => {

                promotItem.cartList.map((cartItem) => {
                  if (cartItem.goodsId == curCartStoreGoodsId.goodsId) {
                    cartItem.isChecked = !isCheckedState;
                  }
                });
              });
            });
            curStoreCartSelChecked();

          } else if (type == "store") {
            //单个店铺
            cartData.data.cartGoods.map((storeItem) => {
              if (storeItem.storeId == curCartStoreGoodsId.storeId) {
                storeItem.checkedAll = !isCheckedState;
              }
            });
            cartData.data.cartGoods.map((storeItem) => {
              storeItem.promotionCartGroupList.map((promotItem) => {

                promotItem.cartList.map((cartItem) => {
                  if (storeItem.storeId == curCartStoreGoodsId.storeId) {
                    cartItem.isChecked = storeItem.checkedAll;
                  }
                });
              });
            });
            curStoreCartSelChecked();
          } else if (type == "all") {
            cartData.data.cartGoods.map((storeItem) => {
              storeItem.checkedAll = !isCheckedState;
              storeItem.promotionCartGroupList.map((promotItem) => {

                promotItem.cartList.map((cartItem) => {
                  cartItem.isChecked = !isCheckedState;
                });
              });
            });
            cartData.data.checkedAll = !isCheckedState;
          }
          colculationOperation();
          updateLocCartData(cartData.data);
        }
      };

      //移入收藏夹 cartId: 商品购物车id集(当前商品购物车id)
      const moveCartGoods = (cartId) => {
        let params = {
          cartIds: cartId,
        };
        proxy
          .$post("v3/business/front/cart/moveToCollection", params)
          .then((res) => {
            if (res.state == 200) {
              cartData.data = res.data;
              cartData.data = proxy.$calculateSubtotal(cartData.data);
              ElMessage.success(L["移入收藏夹成功！"]);
              store.commit('updateCartListData', cartData.data);
            } else {
              ElMessage.error(res.msg);
            }
          });
      };

      //删除商品 type:'goods'单个商品 curCartItem: 当前商品信息    batch:批量
      const delCartGoods = (type, curCartItem) => {
        if (store.state.loginFlag) {
          //已登录
          if (type == "goods") {
            delGoodsCartIds.value = curCartItem.skuCode;
            curCartItem.promotionModel = false
          } else if (type == "batch") {
            cartData.data.cartGoods.map((promotItem) => {
              promotItem.goodsCartGoodsRespList.map((cartItem) => {
                if (cartItem.isChecked == 1) {
                  delGoodsCartIds.value += cartItem.skuCode + ",";
                }
              });
            });
            delGoodsCartIds.value = delGoodsCartIds.value.substring(0, delGoodsCartIds.value.length - 1);
          }
        } else {
          //未登录
          if (type == "goods") {
            delType.value = "goods";
            delGoodsCartIds.value = curCartItem.skuCode;
          } else if (type == "batch") {
            delType.value = "batch";
          }
        }
        chosenGoodsModel.value = false
        confirmDelGoodsModel();
      };

      //确定删除商品
      const confirmDelGoodsModel = () => {
        if (store.state.loginFlag) {
          // 已登录
          let params = {
            userId: store.state.memberInfo.memberId,
            skuCodes: delGoodsCartIds.value,
          };
          proxy.$get("api/shopp/cart/delete", params) //原接口：v3/business/front/cart/deleteCarts
            .then((res) => {
              if (res.code == 200) {
                getCartData();
                ElMessage.success(L["删除成功！"]);
                proxy.$getLoginCartListData();
                closePromotionModel()
                acLabelMarkify()
              } else {
                ElMessage.error(res.msg);
              }
            });
        } else {
          // 未登录
          if (delType.value == "goods") {
            //单个商品删除
            cartData.data.cartGoods.map((storeItem, storeIndex) => {
              storeItem.promotionCartGroupList.map((promotItem) => {

                promotItem.cartList.map((cartItem, cartIndex) => {
                  if (cartItem.goodsId == delGoodsCartIds.value) {
                    promotItem.cartList.splice(cartIndex, 1);
                  }
                });
                if (promotItem.cartList.length == 0) {
                  storeItem.promotionCartGroupList = [];
                }
                if (storeItem.promotionCartGroupList.length == 0) {
                  cartData.data.cartGoods.splice(storeIndex, 1);
                }
              });
            });
          } else if (delType.value == "batch") {
            //批量删除
            let curSelGoods = []; //选中的商品
            cartData.data.cartGoods.map((storeItem) => {
              storeItem.promotionCartGroupList.map((promotItem) => {

                curSelGoods = promotItem.cartList.filter(
                  (cartItem) => cartItem.isChecked == 1
                );
              });
            });
            cartData.data.cartGoods.map((storeItem, storeIndex) => {
              storeItem.promotionCartGroupList.map((promotItem) => {

                promotItem.cartList.map((cartItem, cartIndex) => {
                  curSelGoods.map((curSelGoodsItem) => {
                    if (cartItem.goodsId == curSelGoodsItem.goodsId) {
                      promotItem.cartList.splice(cartIndex, 1);
                    }
                  });
                });
                if (promotItem.cartList.length == 0) {
                  storeItem.promotionCartGroupList = [];
                }
                if (storeItem.promotionCartGroupList.length == 0) {
                  cartData.data.cartGoods.splice(storeIndex, 1);
                }
              });
            });
            curStoreCartSelChecked();
          }
          ElMessage.success(L["删除成功！"]);
        }
        updateLocCartData(cartData.data);
      };

      //打开清空失效商品弹框
      const clearInvalidGoods = () => {
        clearInvalidGoodsModel.value = true;
      };
      //确定清空失效商品
      const confirmClearInvalidModel = () => {
        let params = {};
        proxy.$post("v3/business/front/cart/emptyInvalid", params).then((res) => {
          if (res.state == 200) {
            cartData.data.invalidList = [];
            ElMessage.success(L["清空失效商品成功！"]);
          } else {
            ElMessage.error(res.msg);
          }
        });
        clearInvalidGoodsModel.value = false;
      };
      //取消清空失效商品
      const cancelClearInvalidModel = () => {
        clearInvalidGoodsModel.value = false;
      };

      //去商品详情页
      const goGoodsDetail = (skuCode) => {
        proxy.$goGoodsDetail(skuCode);
      };

      //去结算
      const goBuy = () => {
          if(store.state.memberInfo.isLookOrder==0){
              ElMessage.error('暂无权限');
              return
          }
        if(store.state.memberInfo.userType==0&&store.state.memberInfo.hasAccount==1){
          ElMessageBox.confirm(
        '尚未开通钱包，请开通钱包后进行结算',
        '提示',
        {
          confirmButtonText: '立即开通钱包 >',
          // cancelButtonText: '立即开户>',
          confirmButtonClass: 'dilog_button',
          showCancelButton: false,
          // cancelButtonClass: 'classDilog',
          // type: 'warning',
          center: true,
        }
      )
        .then(() => {
          router.push('/member/openAccount')
          return
        })
        .catch(() => {
          // ElMessage({
          //   type: 'info',
          //   message: 'Delete canceled',
          // })
        })
          return
        } 
        if (store.state.loginFlag) {
          if (cartData.data.totalCheck > 0) {
            // router.push({ path: "/buy/confirm", query: { ifcart: true } });
            router.push({ name: "BuyConfirmOrder", state: { data: JSON.stringify({ifcart: true,data: cartData.data}) } });
          } else {
            ElMessage(L["结算商品不能为空！"]);
          }
        } else {
          //未登录提示登录
          ElMessage.error('请登录');
        }
      };
      //关闭登录弹框
      const closeLoingModal = () => {
        loginModalVisibleFlag.value = false;
      };

      //修改选择促销活动 e:v-model的值，cartItem,当前项商品
      const changePromotion = (e, curCartItem) => {
        curPromotion.value = e;
        curPromotionCartItem.data = curCartItem;
      };

      //点击促销，显示促销选择弹框
      const promotionShow = (cartItem) => {
        curPromotionGoodsProductId.value = cartItem.productId;
        curPromotion.value = cartItem.promotionId;
        cartData.data && cartData.data.storeCartGroupList && cartData.data.storeCartGroupList.map((storeItem) => {
          storeItem.promotionCartGroupList.map((promotItem) => {
            promotItem.cartList.map(cartItem => {
              cartItem.promotionModel = false;
            })
            let curPromoGoods = [];
            curPromoGoods = promotItem.cartList.filter((cartItem) => cartItem.productId == curPromotionGoodsProductId.value);
            if (curPromoGoods && curPromoGoods.length > 0) {
              curPromoGoods[0].promotionModel = true;

            }
          })
        });
      };

      //确定选择促销活动
      const selPromotion = () => {
        if (JSON.stringify(curPromotionCartItem.data) != "{}") {
          let curPromotionItem = [];
          curPromotionItem = curPromotionCartItem.data.promotionList.filter(
            (promotionItem) => promotionItem.promotionId == curPromotion.value
          );
          if (curPromotionItem.length > 0) {
            let params = {
              cartId: curPromotionCartItem.data.cartId,
              promotionDescription: curPromotionItem[0].promotionDes.replace(/<font color='red'>|<\/font>/g, ''),
              promotionId: curPromotionItem[0].promotionId,
              promotionType: curPromotionItem[0].promotionType,
            };
            proxy
              .$post("v3/business/front/cart/changePromotion", params)
              .then((res) => {
                if (res.state == 200) {
                  cartData.data = res.data;
                  cartData.data = proxy.$calculateSubtotal(cartData.data);
                  acLabelMarkify()
                  closePromotionModel();
                } else {
                  ElMessage.error(res.msg);
                }
              });
          }
        } else {
          closePromotionModel();
        }
      };
    const handleKey = e => {
            // console.log(e.keyCode)
      if (e.keyCode == 100) {
        return false;
      }
    }
      //关闭促销弹框
      const closePromotionModel = () => {
        cartData.data && cartData.data.storeCartGroupList && cartData.data.storeCartGroupList.map((storeItem) => {
          storeItem.promotionCartGroupList.map((promotItem) => {

            promotItem.cartList.map(cartItem => {
              cartItem.promotionModel = false;
            })
          })
        })
        updateLocCartData(cartData.data);
      };

      //取消选择促销活动
      const cancelPromotion = () => {
        closePromotionModel();
      };

      //去首页
      const goIndex = () => {
        router.push({ path: "/index" });
      };

      //打开优惠券弹框
      const openCouponModel = (storeId) => {
        curStoreId.value = storeId ? storeId : curStoreId.value;
        //获取优惠券
        let params = {
          storeId: curStoreId.value,
          current: curCouponPage.value,
          pageSize: couponPageSize.value
        };
        proxy
          .$get("v3/promotion/front/coupon/storeCouponList", params)
          .then((res) => {
            if (res.state == 200) {
              couponList.data = res.data;
            }
          });
        couponModel.value = true;
      };
      //上一页优惠券
      const handlePrevCilickChangeCoupon = () => {
        curCouponPage.value--;
        openCouponModel();
      };
      //下一页优惠券
      const handleNextCilickChangeCoupon = () => {
        curCouponPage.value++;
        openCouponModel();
      };
      //当前页数优惠券
      const handleCurrentChangeCoupon = () => {
        openCouponModel();
      };

      const delBatchGoods = () => {
        let antiNum = 0
        cartData.data.cartGoods.map((promotItem) => {
            if (promotItem.goodsCartGoodsRespList.filter((cartItem) => cartItem.isChecked == 1).length > 0) {
              antiNum++
            }
          });

        if (antiNum == 0) {
          ElMessage.warning('请选择要删除的商品')
        } else {
          chosenGoodsModel.value = true
        }

      }


      const acLabelMarkify = () => {
        cartData.data?.storeCartGroupList?.map((storeItem) => {
          storeItem.promotionCartGroupList.map((promotItem) => {
            if (promotItem.promotionDes) {
              promotItem.promotionDes = promotItem.promotionDes.replace(/<(.+?)>/g, function (num) { return "<font color='red'>" + num.slice(1, num.length - 1) + "</font>" })
              promotItem.promotionDes = promotItem.promotionDes.replace(/x[\d]/g, function (num) { return "<font color='red'>" + num + "</font>" })
            }

            promotItem.cartList.map((cartItem) => {
              curPromotion.value = cartItem.promotionId;
              cartItem.promotionList.map(listItem => {
                listItem.promotionDes = listItem.promotionDes.replace(/<(.+?)>/g, function (num) { return "<font color='red'>" + num.slice(1, num.length - 1) + "</font>" })
                listItem.promotionDes = listItem.promotionDes.replace(/x[\d]/g, function (num) { return "<font color='red'>" + num + "</font>" })
              })
            });
          });
        });
      }

      //暴露的变量及方法
      return {
        L,
        token,
        cartData,
        editNum,
        clearInvalidGoods,
        clearInvalidGoodsModel,
        confirmClearInvalidModel,
        cancelClearInvalidModel,
        changeSelectState,
        confirmDelGoodsModel,
        moveCartGoods,
        delCartGoods,
        delGoodsCartIds,
        goGoodsDetail,
        goBuy,
        changePromotion,
        curPromotion,
        selPromotion,
        curPromotionCartItem,
        cancelPromotion,
        goIndex,
        couponModel,
        openCouponModel,
        couponList,
        cartListData,
        loginModalVisibleFlag,
        closeLoingModal,
        optionsBtn,
        optionsBtnTop,
        windowHeight,
        optionsBtnShow,
        promotionShow,
        curPromotionGoodsProductId,
        curCouponPage,
        couponPageSize,
        handlePrevCilickChangeCoupon,
        handleNextCilickChangeCoupon,
        handleCurrentChangeCoupon,
        curStoreId,
        elementScrollTop,
        delBatchGoods,
        chosenGoodsModel,
        platform,
        handleKey
      };
    },
  };
</script>

<style lang="scss">
  @import "../../style/base.scss";
  @import "../../style/iconfont.css";
  @import "../../style/cartIndex.scss";
  .dilog_button {
 background-color: #fff  !important;
 color: #409eff !important; 
 margin: 0 auto;  
border: none;
&:hover {
    background-color: #fff;
    color: #409eff; 
    border: none;
}
}
  .el-radio {
    margin-bottom: 15px;
    white-space: unset;
    vertical-align: top;
    margin-right: 20px;
  }

  .el-radio__label {
    width: 240px;
    /* overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
    display: inline-block;
    word-break: break-all;
    vertical-align: top;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: $colorMain2;
    background: $colorMain2;
  }

  .el-radio__input.is-checked+.el-radio__label {
    color: $colorMain2;
  }

  .promotion_btn {
    span {
      width: 40px;
      height: 20px;
      background: $colorMain;
      border-radius: 3px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      display: inline-block;
      text-align: center;
      line-height: 20px;
      font-weight: 600;
      cursor: pointer;

      &:nth-child(2) {
        margin-left: 40px;
        background: #ffffff;
        color: $colorMain;
      }
    }
  }

  .el-overlay {
    background-color: rgba(0, 0, 0, 0.3);
  }
</style>