<!-- // 售后详情页面 -->
<template>
    <div class="sld_refund_detail" v-if="refundDetail.data">
        <!-- 退款进度 start -->
        <div class="refund_detail_top flex_row_center_center"
            v-if="returnLogList.data && returnLogList.data.length > 0">
            <div class="refund_detail_status">
                <p class="detail_ordersn">{{ returnLogList.data[returnLogList.data.length-1].afterType == 1 ? L['退款单号'] :returnLogList.data[returnLogList.data.length-1].afterType == 3? L['退款退货单号']:'换货单号:'}}{{refundDetail.data.afterOrderCode}}</p>
                <p class="detail_top_status">{{refundDetail.data.returnStateName}}</p>
                <p class="status_option"  v-if="(refundDetail.data.afsType == 1 || refundDetail.data.afsType == 3) && refundDetail.data.state == 202">
                    <div @click="reApplyReFund">重新申请</div>
                </p>
            </div>
            <div class="refund_speed">
                <div class="refund_speed_des">{{returnLogList.data.createTime}}
                    {{returnLogList.data.content}}
                </div>
                <!--
                    退货退款状态：100-买家申请仅退款；101-买家申请退货退款；102-买家退货给商家；200-商家同意退款申请；201-商家同意退货退款申请；202-商家拒绝退款申请(退款关闭/拒收关闭)；203-商家确认收货；300-平台确认退款(已完成)
                    afsType 售后服务端类型，1-退货退款单（需关联处理退款金额），2-换货单，3-仅退款单
                -->
                <div class="refund_speed_con flex_row_center_start">
                    <div class="refund_speed_pre flex_column_center_center">
                        <img src="@/assets/order/t_step1.png" alt="">
                        <span>{{L['提交申请']}}</span>
                    </div>
                    <div class="refund_speed_line">
                        <img src="@/assets/order/t_line.png" alt="">
                    </div>
                    <div class="refund_speed_pre flex_column_center_center">
                        <img src="@/assets/order/t_step_b.png" alt="" v-if="refundDetail.data.returnState == 0">
                        <img src="@/assets/order/t_step2.png" alt="" v-else>
                        <span :class="{refund_noactive:refundDetail.data.returnState == 0}">{{L['审核中']}}</span>
                    </div>
                    <div class="refund_speed_line">
                        <img src="@/assets/order/t_line_b.png" alt="" v-if="refundDetail.data.returnState == 0">
                        <img src="@/assets/order/t_line.png" alt="" v-else>
                    </div>
                    <div class="refund_speed_pre flex_column_center_center">
                        <img src="@/assets/order/t_step3.png" alt="" v-if="refundDetail.data.returnState == 1">
                        <img src="@/assets/order/t_step3_b.png" alt="" v-else>
                        <span :class="{refund_noactive:refundDetail.data.returnState != 1}">{{L['完成']}}</span>
                    </div>
                    <div class="refund_success_fail" v-if="refundDetail.data.returnState == 1 || refundDetail.data.returnState==2">
                        <img src="@/assets/order/t_fail.png" alt="" v-if="refundDetail.data.returnState == 2">
                        <img src="@/assets/order/t_success.png" alt="" v-if="refundDetail.data.returnState == 1">
                    </div>
                </div>
            </div>
        </div>
        <!-- 退款进度 end -->
        <!-- 退款日志 start -->
        <div class="refund_journal flex_row_start_start" v-if="refundDetail.data.goodsDetails && refundDetail.data.goodsDetails.length>0">
            <div class="refund_goods flex_column_start_center">
                <div class="refund_goods_img" :style="{backgroundImage:'url(' + refundDetail.data.goodsDetails[0].goodsImage +')'}">
                </div>
                <p class="refund_goods_name">{{refundDetail.data.goodsDetails[0].goodsNameJoin}}</p>
                <p class="refund_goods_specvalue">{{refundDetail.data.remarks}}</p>
                <p class="refund_goods_price">￥{{refundDetail.data.returnMoney}}</p>
            </div>
            <div class="refund_journal_con">
                <div class="refund_journal_pre flex_row_start_start" v-for="(logItem,logIndex) in returnLogList.data" :key="logIndex">
                    <div class="journal_left flex_column_start_center">
                        <span></span>
                        <span :class="{active: true}"></span>
                    </div>
                    <div class="journal_right">
                        <div class="journal_des">{{logItem.afterType==1?"买家申请仅退款":logItem.afterType==3?"买家申请退货退款":logItem.afterType==4?"买家申请换货":""}}</div>
                        <div class="journal_time refund_address" v-show="logItem.state==201">
                            <div class="title">退货地址：</div>
                            <div class="address">
                                {{refundDetail.data.storeContactName}}&nbsp;{{refundDetail.data.storeTelphone}}&nbsp;{{refundDetail.data.storeAreaInfo}}&nbsp;{{refundDetail.data.storeAddress}}
                            </div>
                        </div>
                        <div class="journal_time">{{L['退款金额']}}：{{logItem.returnMoney}}</div>
                        <div class="journal_time">{{L['申请件数']}}：{{logItem.afterNum}}</div>
                        <div class="journal_time" :title="logItem.afterReason">
                            <span>{{L['退款原因']}}：</span>
                            <span class="des">{{logItem.afterReason?logItem.afterReason:";暂无"}}</span>
                        </div>
                        <div class="journal_time" :title="logItem.remarks">
                            <span>{{L['问题描述']}}：</span>
                            <span class="des">{{logItem.remarks?logItem.remarks:"暂无"}}</span>
                        </div>
                        <div class="journal_time refund_voucher flex_row_start_start">
                            {{L['退款凭证']}}：
                            <div class="refund_voucher_list flex_row_start_start">
                                <div v-if="logItem.images.length ==0">暂无</div>
                                <div class="refund_voucher_pre" v-else v-for="(applyImgItem,applyImgIndex) in logItem.images" :key="applyImgIndex">
                                    <el-image :src="applyImgItem" :preview-src-list="logItem.images" fit="cover"></el-image>
                                </div>
                            </div>
                        </div>
                        <div class="journal_time" v-if="!logItem.isOfflineMailing&&logItem.returnNumber">
                        <span>退货单号:</span><span>{{logItem.returnNumber}}</span>
                        </div> 
                        <div class="journal_time" v-if="logItem.isOfflineMailing">
                        <span>退货方式 </span>: <span>{{logItem.isOfflineMailing?"线下邮寄":""}}</span>
                        </div> 
                        <div class="journal_time" style="display:flex" v-if="!logItem.isOfflineMailing&&logItem.logisticsImage&&logItem.logisticsImage.length!==0">
                        <span>退货单凭证：</span>
                        <div  class="refund_voucher_pre"  v-for="item in logItem.logisticsImage" :key="item"> <el-image :src="item" :preview-src-list="logItem.logisticsImage" fit="cover" style="margin-right:10px"></el-image></div>
                        </div>
                        <div class="journal_time refund_remark" v-show="logItem.platformRemark"
                            :title="logItem.platformRemark">
                            <span>{{L['平台审核备注']}}：</span>
                            <span>{{logItem.platformRemark}}</span>
                        </div>

                        <div class="journal_time">{{L['时间']}}：{{logItem.applyTime}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 退款日志 end -->
    </div>
</template>

<script>
    import { reactive, getCurrentInstance, ref, onMounted } from "vue";
    import { ElMessage } from "element-plus";
    import { useRoute, useRouter } from "vue-router";

    export default {
        setup() {
            const { proxy } = getCurrentInstance();
            const L = proxy.$getCurLanguage();
            const route = useRoute();
            const orderState = ref(''); //订单状态
            const afsSn = ref(''); //售后单号
            const orderId = ref(''); //售后单号
            const refundDetail = reactive({ data: {} }); //售后详情
             const returnLogList = reactive({ data: [] }); //售后日志
            
            const sourceType = ref(1); //仅退款 ；退款退货的来源类型 1：仅退款 2：退款退货
            const router = useRouter()
            onMounted(() => {
                orderState.value = route.query.orderState;
                afsSn.value = route.query.afsSn;
                orderId.value = route.query.orderId;
                sourceType.value = route.query.curTitleId;
                getRefundOrderDetail();
            });
            //获取售后订单详情
            const getRefundOrderDetail = () => {
                let params = {
                    afterOrderCode: orderId.value
                }
                proxy.$get('api/shopp/order/after/detail', params).then((res) => {   //原接口：  v3/business/front/after/sale/detail
                    if (res.code == 200) {
                        refundDetail.data = res.data;
                         
                           
                        
                        // res.data.goodsDetails.map((logItem, logIndex)=>{
                        //     if (logItem.state == 101||logItem.state == 100) {
                        //         logItem.applyNum = logItem.returnNum;
                        //         logItem.returnMoneyAmount = logItem.returnMoneyAmount;
                        //         logItem.afsDescription = logItem.afsDescription;
                        //         logItem.applyReasonContent = logItem.applyReasonContent;
                        //         logItem.applyImageList = logItem.applyImageList; //退款凭证
                        //     }
                        //     if (logIndex == 0) {
                        //         logItem.platformRemark = refundDetail.data.platformRemark;
                        //     }
                        //     returnLogList.data  = returnLogList.data.concat(logItem.returnLogList)
                        // })
                        returnLogList.data = res.data.logs
                        returnLogList.data.forEach((item)=>{
                            if(item.logisticsImage){
                                item.logisticsImage=item.logisticsImage.split(",")
                            }
                           
                        })
                        
                        // returnLogList.data = returnLogList.data.reverse();
                        // returnLogList.data.map((logItem, logIndex) => {
                        //     if (logItem.state == 100) {
                        //         logItem.applyNum = refundDetail.data.returnNum;
                        //         logItem.returnMoneyAmount = refundDetail.data.returnMoneyAmount;
                        //         logItem.afsDescription = refundDetail.data.afsDescription;
                        //         logItem.applyReasonContent = refundDetail.data.applyReasonContent;
                        //         logItem.applyImageList = refundDetail.data.applyImageList; //退款凭证
                        //     }
                        //     if (logIndex == 0) {
                        //         logItem.platformRemark = refundDetail.data.platformRemark;
                        //     }
                        // })
                        console.log(refundDetail.data)
                    } else {
                        ElMessage.error(res.data)
                    }
                })
            }

            const reApplyReFund = () => {
                let { orderSn, orderProductId } = refundDetail.data
                proxy.$get('v3/business/front/after/sale/apply/applyInfo', {orderSn,orderProductId}).then(res => {
                    if (res.state == 200) {
                        router.push({
                            path: "/member/order/refund/apply",
                            query: {
                                orderState: orderState.value,
                                orderProductId
                            }
                        });
                    } else {
                        ElMessage(res.msg)
                    }
                })
            }

            return {
                L,
                orderState,
                afsSn,
                refundDetail,
                sourceType,
                reApplyReFund,
                returnLogList
            };
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../../style/base.scss";
    @import "../../../style/iconfont.css";
    @import "../../../style/refundDetail.scss";

    .el-image {
        width: 50px;
        height: 50px;
    }

    .refund_remark {
        word-break: break-all;
    }
</style>