<template>
    <div class="sld_login">
        <div class="sld_login_header">
            <div class="content">
                <router-link tag="a" class="l_logo" :to="`/index`">
                    <img class="img" :src="platform==1?gongcaiLogo:jicaiLogo" :onerror="defaultImg" alt />
                </router-link>
                <div class="r_register_wrap">
                    {{L['我已知悉？']}}
                    <a class="go_register_btn" @click="goRegister">
                        {{L['去注册']}}
                    </a>
                </div>
            </div>
        </div>
        <div class="agreement_container">
            <h2 class="agreement_title">{{agreeContent.title}}</h2>
            <pre class="agreement_content" v-html="agreeContent.content"></pre>
        </div>
    </div>
</template>

<script>
    import { useRouter,useRoute } from 'vue-router'
    import { ref, getCurrentInstance, reactive, onMounted } from 'vue';
    import { useStore } from "vuex";
    import { platform } from '@/utils/config';
    export default {
        name: "Agreement",
        setup() {
            const store = useStore();
            const route = useRoute();
            const router = useRouter()
            const { proxy } = getCurrentInstance();
            const L = proxy.$getCurLanguage();
            const configInfo = ref(store.state.configInfo);
            const gongcaiLogo = ref(require('@/assets/gongcaiLogo.jpg'));
            const jicaiLogo = ref(require('@/assets/jicaiLogo.jpg'));
            const defaultImg = require('@/assets/common_top_logo.png');
            const agreeContent = reactive({
                content: '',
                title: ''
            })
            const agreementCode = reactive({
                agreementCode: 'register_agreement'
            })
            const getInitData = () => {
                proxy.$get('/v3/system/front/agreement/detail', agreementCode).then(res => {
                    if (res.state == 200) {
                        agreeContent.content = proxy.$quillEscapeToHtml(res.data.content)
                        agreeContent.title = res.data.title
                    }
                })
            }

            const goRegister = () => {
                // router.push({
                //     path: '/register'
                // })
                router.back();
            }
            onMounted(() => {
                agreementCode.agreementCode = route.query.code || 'register_agreement'
                getInitData()
            })

            return { L, agreeContent, goRegister, configInfo,gongcaiLogo,jicaiLogo,platform, defaultImg  }
        },
    };
</script>
<style lang="scss" scoped>
    @import '../../../style/agreement.scss';
    .agreement_container{
        width: 800px;
        
    }
    .agreement_content{
        font-size: 15px;
        line-height: 35px;
        white-space:normal;
        word-break:break-all;
    }
    
</style>
<style lang="scss">
.agreement_content {
  img {
    max-width: 100%;
  }

  a {
    display: inline-block;
    margin: 5px auto;
    color: #0000ff;
    text-decoration: underline;
  }

  table {
    border-collapse: collapse;
    padding: 0;
  }
  td,
  th {
    border: 1px solid #ddd;
    padding: 5px 10px;
  }

  ol li,
  ul li {
    list-style: unset;
  }
}
</style>
